import { CRYPTO_SECRET, DAYS_7 } from "../constants/constants";

// Crypto import, keep all imports on top of this.
var CryptoJS = require("crypto-js");

export const generateTime = () => {
  const time = Date.now() + DAYS_7;
  return Math.floor(time / (1000 * 100));
};

export const Encyption = (userId, resumeCount) => {
  const parsedId = userId + "," + generateTime() + "," + resumeCount;
  // console.log("dataaaa", parsedId);
  let encrypted = CryptoJS.AES.encrypt(parsedId, CRYPTO_SECRET).toString();
  return CryptoJS.enc.Base64.parse(encrypted).toString(CryptoJS.enc.Hex);
};

export const expiryTime = () => {
  const time = Date.now() + DAYS_7;
  return Encyption(time);
};

import React, { Fragment, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { LOGIN_PATH, ROOT_PATH } from '../../utils/constants/constants';

const FirstLogin = () => {

    const navigate = useNavigate();
    const { userId } = useParams()

    useEffect(()=>{
        const user = JSON.parse( localStorage.getItem('user') );
     

        if(!user){
            navigate( LOGIN_PATH )
        }
        else if((user?.id !== userId) ){
            localStorage.clear()
            navigate( LOGIN_PATH )
        }
        else{
            navigate( ROOT_PATH )
        }
    },[navigate, userId]);

    return <Fragment>
        <Loader />
    </Fragment>
}

export default FirstLogin;
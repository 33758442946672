import React, { Fragment, useState } from "react";
import Container from "@mui/material/Container";
import { styles } from "../../styles/styles";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormikAutocomplete, FormikTextField } from "../FormControlsWithFormik";
import {
  DELETE_ICON,
  MONTHS_NAME,
  OPEN_ICON,
  YEARS,
} from "../../utils/constants/constants";
import _ from "lodash";
import { Field } from "formik";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const ProfessionalInfoForm = (props) => {
  const CURRENT_YEAR = new Date().getFullYear();
  const CURRENT_MONTH = new Date().getMonth();
  const classes = styles();
  const { values } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [flag, setFlag] = useState(true);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [openIcon, setOpenIcon] = useState(-1);
  const [proHis_isChecked, setProHis_Checked] = useState(false);

  // handle index and previous saved data
  const listLength =
    values.professionalHistory?.length !== 0
      ? values.professionalHistory?.length - 1
      : 0;

  const previousSavedData = listLength === 0 ? [] : values.professionalHistory;

  const [listIndex, setListIndex] = useState(listLength);
  const [showProfessionalList, setShowProfessionalList] =
    useState(previousSavedData);

  const handleChange = () => {
    setIsChecked(!isChecked);
    values.professionalHistory[listIndex].isCurrentEmployee = !isChecked;
    if (!isChecked) {
      values.professionalHistory[listIndex].endDate =
        JSON.stringify(CURRENT_YEAR);
      values.professionalHistory[listIndex].endMonth =
        MONTHS_NAME[CURRENT_MONTH];
    }
  };

  const checkValidation = () => {
    return !(
      _.isEmpty(values?.professionalHistory[listIndex].jobTitle) ||
      _.isEmpty(values?.professionalHistory[listIndex].employeer) ||
      _.isEmpty(values?.professionalHistory[listIndex].desc)
    )
      ? true
      : false;
  };

  const checkEmptyFields = () => {
    let empty = false;
    for (const edu of values.professionalHistory) {
      if (
        _.isEmpty(edu.jobTitle) ||
        _.isEmpty(edu.employeer) ||
        _.isEmpty(edu.startDate + "") ||
        _.isEmpty(edu.endDate + "") ||
        _.isEmpty(edu.startMonth) ||
        _.isEmpty(edu.endMonth) ||
        _.isEmpty(edu.desc)
      ) {
        empty = true;
        break;
      }
    }
    setListIndex(values.professionalHistory?.length - 1);
    return empty;
  };

  const handleAddProfessionInfo = (e) => {
    e.preventDefault();
    if (!checkEmptyFields()) {
      setShowProfessionalList(values?.professionalHistory);
      setDeleteBtn(true);
      props.formikProps.setFieldValue("professionalHistory", [
        ...values?.professionalHistory,
        {
          jobTitle: "",
          employeer: "",
          startDate: "",
          endDate: "",
          isCurrentEmployee: false,
          desc: "",
          // isBulletin: "",
        },
      ]);
      setListIndex(listIndex + 1);
      setIsChecked(false);
      setOpenIcon(-1);
    }
  };

  const handleShow = (data, index) => {
    setIsChecked(false);
    if (checkValidation()) {
      setOpenIcon(index);
      setListIndex(index);
      setShowProfessionalList(values?.professionalHistory);
    } else {
      // values?.professionalHistory?.pop();
      setOpenIcon(index);
      setListIndex(index - 1);
      setShowProfessionalList(values?.professionalHistory);
      setListIndex(index);
    }
  };

  const handleDelete = (data, index) => {
    showProfessionalList.splice(index, 1);
    values?.professionalHistory.splice(index, 1);
    flag ? setFlag(false) : setFlag(true);
    setListIndex(listIndex - 1);
    if (values?.professionalHistory?.length === 0) {
      props.formikProps.setFieldValue("professionalHistory", [
        {
          jobTitle: "",
          employeer: "",
          startDate: "",
          endDate: "",
          isCurrentEmployee: false,
          desc: "",
          isBulletin: false,
        },
      ]);
    }
  };

  const handleOpenDelete = () => {
    setDeleteBtn(false);

    values?.professionalHistory?.pop();
    setOpenIcon(values?.professionalHistory?.length - 1);
    setListIndex(values?.professionalHistory?.length - 1);
  };
  console.log("values.professional", values.professionalHistory);
  const warning =
    values.professionalHistory.filter((item) => {
      return !item.jobTitle || !item.employeer || !item.desc;
    }).length !== 0;
  console.log("warning", warning);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(showProfessionalList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setShowProfessionalList(items);
    props.formikProps.setFieldValue("professionalHistory", items);
    console.log(result);
  }

  return (
    <Fragment>
      <Container component="main" className={classes.updatePasswordContainer}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
            // minHeight: "85vh",
          }}
          className={classes.stepperContainer}
        >
          {warning ? (
            <div style={{ color: "red", fontWeight: "bold" }}>
              Please fill all the required fields in all proffessional history
              details.
            </div>
          ) : (
            <></>
          )}
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h4
                style={{
                  textAlign: "left",
                  margin: 0,
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Professional History
              </h4>
              <p>Mention the most recent employment first</p>
            </div>
            <div
              style={{ alignSelf: "center" }}
              onClick={handleAddProfessionInfo}
            >
              <button className={classes.logoutbtn}>Add</button>
            </div>
          </Box>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="showProfessionalList">
              {(provided) => (
                <div className="showProfessionalList" {...provided.droppableProps} ref={provided.innerRef}>
                  {showProfessionalList.length > 0 &&
                    showProfessionalList?.map((proList, index) => {
                      return (
                        <Draggable key={proList?._id} draggableId={proList?._id} index={index}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "0.6rem 0",
                                  borderTop: "1px solid rgba(127, 129, 130, 0.3)",
                                  borderBottom: "1px solid rgba(127, 129, 130, 0.3)",
                                }}
                              >
                                <div>
                                  <p style={{ margin: 0 }}>
                                    {`${proList?.jobTitle} at ${proList.employeer
                                      }, ${new Date(proList.startDate).getFullYear()} - ${proList.isCurrentEmployee
                                        ? "present"
                                        : new Date(proList.endDate).getFullYear()
                                      }`}
                                  </p>
                                </div>
                                <div>
                                  <img
                                    alt=""
                                    src={OPEN_ICON}
                                    style={
                                      openIcon === -1 || openIcon !== index
                                        ? { width: 20, height: 20,cursor:"pointer" }
                                        : {
                                          width: 20,
                                          height: 20,
                                          transform: "rotateX(180deg)",
                                          cursor:"pointer"
                                        }
                                    }
                                    onClick={() => handleShow(proList, index)}
                                  />
                                  <img
                                    alt=""
                                    src={DELETE_ICON}
                                    style={{ width: 20, height: 20, marginLeft: 10 ,cursor:"pointer"}}
                                    onClick={() => handleDelete(proList, index)}
                                  />
                                </div>
                              </Box>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {values?.professionalHistory?.length > 1 && deleteBtn ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1rem",
              }}
            >
              <img
                alt=""
                src={DELETE_ICON}
                style={{ width: 20, height: 20, marginLeft: 10 }}
                onClick={handleOpenDelete}
              />
            </Box>
          ) : null}

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <div style={{ width: "100%", marginRight: "1rem" }}>
              <h2 style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}>
                Job Title *
              </h2>
              <FormikTextField
                fullWidth
                margin="normal"
                required
                className={classes.inputField}
                label="Eg. Senior Software Engineer "
                variant="outlined"
                name={`professionalHistory[${listIndex}].jobTitle`}
              />
            </div>
            <div style={{ width: "100%" }}>
              <h2 style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}>
                Employeer*
              </h2>
              <FormikTextField
                margin="normal"
                required
                className={classes.inputField}
                label="Eg. Google"
                fullWidth
                name={`professionalHistory[${listIndex}].employeer`}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <div style={{ width: "100%", marginRight: "1rem" }}>
              <h2
                style={{
                  color: "#7F8182",
                  fontSize: "18px",
                  margin: 0,
                  marginBottom: "20px",
                }}
              >
                Start Employment
              </h2>
              {/* <FormikTextField
                
                fullWidth
                margin="normal"
                required
                className={classes.inputField}
                variant="outlined"
                name={`professionalHistory[${listIndex}].startDate`}
                type="month"
                value={values.professionalHistory[listIndex].startDate}
              /> */}
              <div style={{ display: "flex" }}>
                <Field
                  name={`professionalHistory[${listIndex}].startDate`}
                  fullWidth
                  label={"Start year"}
                  component={FormikAutocomplete}
                  options={YEARS}
                  textFieldProps={{
                    variant: "outlined",
                  }}
                  required
                />
                <Field
                  name={`professionalHistory[${listIndex}].startMonth`}
                  fullWidth
                  label={"Start month"}
                  component={FormikAutocomplete}
                  options={MONTHS_NAME}
                  textFieldProps={{
                    variant: "outlined",
                  }}
                  required
                  sx={{ marginLeft: "10px" }}
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <h2
                style={{
                  color: "#7F8182",
                  fontSize: "18px",
                  margin: 0,
                  marginBottom: "20px",
                }}
              >
                End Employment
              </h2>

              <div style={{ display: "flex" }}>
                <Field
                  name={`professionalHistory[${listIndex}].endDate`}
                  fullWidth
                  label={"End year"}
                  component={FormikAutocomplete}
                  options={YEARS}
                  textFieldProps={{
                    variant: "outlined",
                  }}
                  required
                  disabled={isChecked}
                />
                <Field
                  name={`professionalHistory[${listIndex}].endMonth`}
                  fullWidth
                  label={"End month"}
                  component={FormikAutocomplete}
                  options={MONTHS_NAME}
                  textFieldProps={{
                    variant: "outlined",
                  }}
                  sx={{ marginLeft: "10px" }}
                  required
                  disabled={isChecked}
                />
              </div>
            </div>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                onChange={handleChange}
                control={<Checkbox checked={isChecked} />}
                label="I Currently Work Here"
                value={isChecked}
              />
            </FormGroup>
          </Box>

          <div style={{ width: "100%" }}>
            <h2 style={{ color: "#7F8182", fontSize: "18px" }}>Description</h2>
            <FormGroup>
              <FormControlLabel
                onChange={(e) => {
                  setProHis_Checked(!proHis_isChecked);
                  props.formikProps.setFieldValue(
                    `professionalHistory[${listIndex}].isBulletin`,
                    e.target.checked
                  );
                }}
                control={
                  <Checkbox
                    checked={values?.professionalHistory[listIndex]?.isBulletin}
                  />
                }
                label="change to Bullets"
                value={values?.professionalHistory[listIndex]?.isBulletin}
              />
            </FormGroup>
            <Box sx={{ display: "flex", width: "100%" }}>
              <FormikTextField
                aria-label="empty textarea"
                placeholder="Enter your work Experience"
                style={{
                  width: "100%",
                  fontSize: 18,
                  color: "grey",
                }}
                rows={7}
                name={`professionalHistory[${listIndex}].desc`}
                multiline
              />
            </Box>
          </div>
        </Box>
      </Container>
    </Fragment>
  );
};

export default ProfessionalInfoForm;

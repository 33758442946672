import { CircularProgress } from '@mui/material';
import React from 'react';

const SmallLoader = ({ alignment }) => {

    return <div style={{textAlign:alignment || 'center'}} >
            <CircularProgress style={{ marginRight:'8px' }}  size={20}/>
        </div>
}

export default SmallLoader;
import {
  EMPLOYEES_PATH,
  PROFILE,
  ROOT_PATH,
  SETTINGS_PATH,
  UPDATE_PASSWORD,
  DASHBOARD_PATH,
  RESUME_COMPLETE_PATH,
  RESUME_EDIT_PATH,
  EDIT_RESUME_PATH,
  FIRST_LOGIN_PATH,
  RESUME_LIST_PATH,
} from "../utils/constants/constants";
import UserDashboard from "../screens/UserDashboard/UserDashboard";
import UpdatePassword from "../screens/UpdatePassword/UpdatePassword";
import Profile from "../screens/Profile/Profile";
import Settings from "../screens/settings/Settings";
import Employees from "../screens/Employees/Employees";
import Dashboard from "../screens/Dashboard/Dashboard";
import ResumeComplete from "../screens/ResumeComplete/ResumeComplete";
import FirstLogin from "../screens/FirstLogin/FirstLogin";
import ResumeList from "../screens/ResumeList/ResumeList";

export const userRoutes = [
  {
    path: ROOT_PATH,
    component: ResumeList,
  },
  {
    path: DASHBOARD_PATH,
    component: ResumeList,
  },
  {
    path: RESUME_EDIT_PATH,
    component: UserDashboard,
  },

  {
    path: PROFILE,
    component: Profile,
  },
  {
    path: UPDATE_PASSWORD,
    component: UpdatePassword,
  },
  {
    path: RESUME_COMPLETE_PATH,
    component: ResumeComplete,
  },
  {
    path: SETTINGS_PATH,
    component: Settings,
  },
  {
    path: EMPLOYEES_PATH,
    component: Employees,
  },
  {
    path: `${EDIT_RESUME_PATH}/:id`,
    component: UserDashboard,
  },
  {
    path: FIRST_LOGIN_PATH,
    component: FirstLogin,
  },

  { path: RESUME_LIST_PATH, component: ResumeList },
];

import React, { Fragment, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { LOGIN_PATH } from '../../utils/constants/constants';
import { checkLoggedIn } from '../../api/Auth';

const Settings = () => {

    const navigate = useNavigate();

    useEffect(()=>{
        const isLoggedIn = checkLoggedIn();
        if(!isLoggedIn){
            navigate( LOGIN_PATH );
        }
    },[navigate]);

    const handleSingOut = ( ) => {
        localStorage.clear();
        navigate( LOGIN_PATH )
    }

    return <Fragment>
        <Button variant='contained' style={{background:'#F47954'}} onClick={ handleSingOut } >Sign out</Button>
    </Fragment>
}

export default Settings;
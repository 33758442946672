import React, { useEffect } from "react";
import { Box } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { makeStyles } from "@mui/styles";
import {
  BASE_URL_WITHOUT_PORT,
  DASHBOARD_PATH,
  DONE_GIF,
  EDIT_RESUME_PATH,
  PREVIEW_IN_BETWEEN,
  RESUME_EDIT_PATH,
  RESUME_LIST_PATH,
} from "../../utils/constants/constants";
import { useNavigate } from "react-router-dom";
import { Encyption } from "../../utils/helper/crypto";
import { canEditResume } from "../../utils/helper/checkDesignation";
import { WindowSharp } from "@mui/icons-material";

const useStyles = makeStyles({
  box: {
    backgroundColor: "#fff",
    height: "85vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    textAlign: "center",
    margin: 0,
  },
  para: {
    maxWidth: "400px",
    margin: "1.5rem auto",
  },
  btn: {
    background: "#F47954",
    color: "white",
    padding: "0.6rem 3rem",
    border: "none",
    borderRadius: 2,
    marginTop: "1.5rem",
    cursor: "pointer",
  },
  editBtn: {
    background: "white",
    color: "#F47954",
    fontSize: "20px",
    fontWeight: "bold",
    border: "none",
    margin: 0,
    marginTop: "1.5rem",
    cursor: "pointer",
  },
  image: {
    width: "180px",
    height: "180px",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    background: "white",
  },
  notchedOutline: { borderColor: "#f0f !important", color: "black" },
  input: {
    background: "rgba(127, 129, 130, 0.03)",
    padding: "5px 10px",
    height: "50px",
    width: "300px",
    fontSize: 16,
    border: "1px solid rgba(127, 129, 130, 0.6)",
    borderRadius: 5,
    color: "#7F8182 ",
  },
});

const ResumeComplete = () => {
  const navigate = useNavigate();
  const [copied, setCopied] = React.useState(false);
  const [url, setUrl] = React.useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    // const previewURL = `${BASE_URL_WITHOUT_PORT}/resume/${user.employeeId}`;
    const canEdit = canEditResume(user.userType);

    if (canEdit) {
      const userId = JSON.parse(localStorage.getItem("userEmpId"));
      const previewURL = `${BASE_URL_WITHOUT_PORT}/${PREVIEW_IN_BETWEEN}`;
      setUrl(previewURL);
    } else {
      const ciphertext = Encyption(user.employeeId);
      const previewURL = `${BASE_URL_WITHOUT_PORT}/${PREVIEW_IN_BETWEEN}/${ciphertext}`;
      setUrl(previewURL);
    }
  }, []);

  const handleCopyText = () => {
    setCopied(true);
    navigator.clipboard.writeText(
      url?.length ? url : "https://www.resourcifi.com/Resume/Shubham"
    );
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <Box className={classes.box}>
        <Box>
          <img src={DONE_GIF} className={classes.image} alt="" />
          <h2 className={classes.title}>
            Great, you have successfully created your Resume!
          </h2>
          <Box className={classes.containPara}>
            <p className={classes.para}>
              Your resume is created successfully, Now you can use the link to
              share resume.
            </p>
          </Box>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <TextField
              placeholder={url?.length ? url : "https://www.resourcifi.com/Resume/Shubham" }
              style={{ minWidth: "300px" }}
              InputProps={ {
                classes: {
                    notchedOutline: classes.notchedOutline,
                    colorPrimary:"black"
                },
                style:{ color:"black !important" },
                disabled:true
                
                
              }}
            /> */}
            <input
              type="text"
              value={
                url?.length ? url : "https://www.resourcifi.com/Resume/Shubham"
              }
              disabled={true}
              className={classes.input}
            />
            <div
              onClick={handleCopyText}
              style={{
                marginLeft: "1rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <ContentCopyIcon color="action" />{" "}
              {copied ? "Link Copied" : "copy link"}
            </div>
          </div>
          <Box>
            <button
              onClick={() => navigate(RESUME_LIST_PATH)}
              className={classes.btn}
            >
              Done
            </button>
          </Box>
          {/* <Box>
            <button
              onClick={() => navigate(RESUME_EDIT_PATH)}
              className={classes.editBtn}
            >
              Want to edit?
            </button>
          </Box> */}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ResumeComplete;

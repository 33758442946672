import { CircularProgress } from '@mui/material';
import React from 'react';

const BtnLoader = ({ alignment }) => {

    return <div style={{textAlign:alignment || 'center'}} >
            <CircularProgress style={{ marginRight:'8px' }}  size={30}/>
        </div>
}

export default BtnLoader;
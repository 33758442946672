import * as React from "react";
import { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import ResourceRadio from "./ResourceRadio";
// import { getSkillList, updateClientStatus } from "../../api/authApi";
// import { useParams } from "react-router-dom";
// import { style } from "@mui/system";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import CircularProgress from "@mui/material/CircularProgress";
import { Encyption, expiryTime } from "../../utils/helper/crypto";
import { endPoints, PREVIEW_IN_BETWEEN } from "../../utils/constants/constants";
import {
  BASE_URL,
  EDIT_RESUME_PATH,
  RESUME_EDIT_PATH,
} from "../../utils/constants/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import {
//   deleteUser,
//   getPdfLink,
//   newResumeRequestService,
// } from "../../api/resumeApi";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
// import { NewResumePopup, DialogBoxPopup } from "./Dialog";

// import SimpleBackdrop from "../../components/BackDrop";
import { getResumeList, getResumeSkillList } from "../../api/api";
import SimpleBackdrop from "../../components/BackDrop";

function Row(props) {
  // const params = useParams();
  // const { id } = params;
  const { row, fecthingUserData, skillList, loading } = props;
  // console.log("row", row);
  // const history = useHistory();
  // const [open, setOpen] = React.useState(false);
  // const [openDelete, setOpenDelete] = React.useState(false);
  // const [openAdd, setOpenAdd] = React.useState(false);

  const [status, setStatus] = React.useState(row.status);
  // const [selectedRow, setSelectedRow] = React.useState({});
  // const [copied, setCopied] = useState("");

  const [deleteuser, setDeleteuser] = useState({});
  const [skills, setSkills] = useState([]);
  const { rows, setData } = props;
  const [text, setText] = useState("");
  const navigate = useNavigate();
  // // console.log("selectedRow====", selectedRow);
  // const getRowData = (open, row) => {
  //   // setLoading(true);
  //   setOpen(open);
  //   setSelectedRow(row);
  // };

  const handleEdit = (row) => {
    // if (row?.resumeStatus === "Complete") {
    navigate(EDIT_RESUME_PATH + `/resumeId=${row?._id}`);
    // }
  };

  const handleVisible = (row) => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (row?.resumeStatus === "Complete") {
      const cipherText = Encyption(user.employeeId, row.resumeCount);

      /// testing
      // const previewURL = `${BASE_URL}/api/resume-detail/profile/${cipherText}`;
      // window.open(previewURL);
      ////
      // live
      const previewURL = `${BASE_URL}/${PREVIEW_IN_BETWEEN}/${cipherText}`;
      window.open(previewURL);
    }
  };

  const selectColor = (row) => {
    if (row?.resumeStatus) {
      if (row?.resumeStatus === "Complete") return "#3EAF3F";
      else if (row?.resumeStatus === "In Progress") return "#F3C607";
      else if (row?.resumeStatus === "Pending") return "#f24e1e";
    } else {
      return "#7F8182";
    }
  };

  return (
    <React.Fragment>
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset", padding: "0px" } }}>
          <TableCell
            component="th"
            scope="row"
            style={{ textAlign: "center", maxWidth: "10px" }}
          >
            {row &&
              row.skillId.map((obj, index) => (
                // console.log("skillId==========================", row.skillId),
                <>
                  {row?.skillId.length - 1 == index
                    ? obj?.skillName + ""
                    : obj?.skillName + ","}
                  &nbsp;
                </>
              ))}

            {row.skillName}
          </TableCell>

          <TableCell
            component="th"
            scope="row"
            style={{
              color: selectColor(row),
              margin: 0,
              fontWeight: "500",
              fontSize: 14,
              textAlign: "center",
            }}
          >
            {row.resumeStatus}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            style={{
              margin: 0,
              fontWeight: "500",
              fontSize: 14,
              textAlign: "center",
            }}
          >
            {moment(row.createdAt).format("DD-MM-YYYY")}
          </TableCell>

          <TableCell style={{ textAlign: "center" }}>
            <Tooltip title="View">
              <VisibilityIcon
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "13px",
                }}
                color="action"
                onClick={() => handleVisible(row)}
              />
            </Tooltip>

            <Tooltip title="Edit Resume">
              <EditIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                color="action"
                onClick={() => handleEdit(row)}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
      </>
    </React.Fragment>
  );
}

export default function UserTable() {
  const [newPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(async () => {
    // console.log("resume list");
    setLoading(true);
    let userAllResumes = await getResumeList();
    setRows(userAllResumes?.data?.resumeList || []);
    setLoading(false);
  }, []);

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#E7EBF0" }}>
              <TableCell>
                <Typography>
                  <p
                    style={{
                      height: "11px",
                      fontWeight: "800",
                      fontSize: "16px",
                      // opacity: "0.6",

                      color: "rgb(127, 129, 130)",
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    SKILLS
                  </p>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      // opacity: "0.6",
                      color: "rgb(127, 129, 130)",
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    RESUME STATUS
                  </p>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "rgb(127, 129, 130)",

                      // opacity: "0.6",
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    CREATED AT
                  </p>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "rgb(127, 129, 130)",
                      textAlign: "center",
                      marginRight: "20px",
                    }}
                  >
                    ACTIONS
                  </p>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <div>
                <SimpleBackdrop />
              </div>
            ) : rows.length ? (
              rows.map((row) => (
                <Row
                  style={{ marginRight: "13px" }}
                  key={row._id}
                  row={row}
                  loading={loading}
                  setLoading={setLoading}
                />
              ))
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {showPagination ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null} */}
    </Paper>
  );
}

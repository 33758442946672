import { CircularProgress } from '@mui/material';
import React from 'react';
import Spacer from '../Spacer/Spacer';
import { styles } from '../../styles/styles';

const Loader = ({ alignment }) => {

    const classes = styles()
    return <div style={{textAlign:alignment || 'center'}} >
            <Spacer />
            <CircularProgress className={ classes.loader } />
        </div>
}

export default Loader;
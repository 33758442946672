import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomEventEmitter from "../utils/emitter/EventEmitter";
import MainLoader from "../components/Loader/MainLoader";
import DashboardLayout from "../components/Dashboard/DashboardLayout";
import HomeScreen from "../screens/HomeScreen/HomeScreen";

// All Routes Import
import { authRoutes } from "./authRoutes";
import { userRoutes } from "./userRoutes";

const Router = () => {
  const config = {
    snackBarPlacement: {
      vertical: "bottom",
      horizontal: "right",
    },
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    CustomEventEmitter.on("alert_success", (message) => {
      enqueueSnackbar(message, {
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
      });
    });
    CustomEventEmitter.on("alert_error", (message) => {
      enqueueSnackbar(message, {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
      });
    });
    CustomEventEmitter.on("alert_info", (message) => {
      enqueueSnackbar(message, {
        variant: "info",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
      });
    });
    CustomEventEmitter.on("alert_warning", (message) => {
      enqueueSnackbar(message, {
        variant: "warning",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
      });
    });
    //for alert messages - ends
    return () => {
      CustomEventEmitter.removeEventListener("alert_success");
      CustomEventEmitter.removeEventListener("alert_error");
      CustomEventEmitter.removeEventListener("alert_info");
      CustomEventEmitter.removeEventListener("alert_warning");
    };
  }, [enqueueSnackbar, config.snackBarPlacement]);

  return (
    <BrowserRouter>
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route path="/" element={<HomeScreen />} exact />

          {/* Auth Routes */}
          {authRoutes.map(({ path, component: Component }, idx) => (
            <Route path={path} element={<Component />} key={idx} />
          ))}

          {/* User Routes */}
          {userRoutes.map(({ path, component: Component }, idx) => (
            <Route
              path={path}
              key={idx}
              element={
                <DashboardLayout>
                  <Component />
                </DashboardLayout>
              }
            />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;

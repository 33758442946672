export const isResumeEligible = (designation) => {
  if (designation === "Sales Person" || designation === "Manager") {
    return false;
  } else {
    return true;
  }
};

export const canEditResume = (designation) => {
  if (
    designation === "Sales Person" ||
    designation === "Manager" ||
    designation === "Team Lead"
  ) {
    return true;
  } else {
    return false;
  }
};

import React, { Fragment, useEffect, useState } from "react";
import { MAX_FILE_SIZE, USER_ICON } from "../../utils/constants/constants";
import Container from "@mui/material/Container";
import { styles } from "../../styles/styles";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { FormikAutocomplete, FormikTextField } from "../FormControlsWithFormik";
import { getTabScrollButtonUtilityClass, MenuItem } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { getSkillList, removeProfileImage } from "../../api/api";
import { Field } from "formik";
//import { getSkillList } from "../../../api/authApi";

function getStyles(name, personName) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? 600 : 400,
  };
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const PersonalInformationForm = (props) => {
  const classes = styles();

  const { values } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const [skillName, setSkillName] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [personName, setPersonName] = useState([]);
  // const [showCloseIcon, setShowCloseIcon] = useState(false);

  // const handleImage = (e) => {
  //   // console.log("file", e.target.files[0].size);
  //   if (e?.target?.files[0]) {
  //     if (e.target.files[0].size <= MAX_FILE_SIZE) {
  //       const url = URL.createObjectURL(e?.target?.files[0]);
  //       values.file = e?.target?.files[0];
  //       props.formikProps.setFieldValue("personalInfo.profileImage", url);
  //       values.personalInfo.profileImage = url;
  //       setImageUrl(url);
  //       // setShowCloseIcon(true);
  //     } else {
  //       return alert(
  //         `Select Image Smaller than ${Math.floor(MAX_FILE_SIZE / 1024)}KB`
  //       );
  //     }
  //   }
  // };

  // const deleteProfileImage = () => {
  //   if (values.personalInfo.profileImage) {
  //     removeProfileImage(values.userId)
  //       .then((res) => {
  //         setImageUrl(null);
  //         props.formikProps.setFieldValue("personalInfo.profileImage", null);
  //       })
  //       .catch((error) => {
  //         // console.log(error);
  //       });
  //   } else {
  //     props.formikProps.setFieldValue("personalInfo.profileImage", null);
  //     setImageUrl(null);
  //   }

  //   // setShowCloseIcon(false);
  // };

  const handleSkills = (value) => {
    const data = value.filter(
      (value, index, arr) => index === arr.findIndex((t) => t._id === value._id)
    );
    props.formikProps.setFieldValue("skillId", data);
  };

  useEffect(() => {
    setLoading(true);
    const fetchTeamList = async () => {
      try {
        const skills = await getSkillList();
        setSkillList(skills?.skillList || []);
      } catch (error) {
        CustomEventEmitter.emit("alert_error", "error while fetching teams");
      } finally {
        setLoading(false);
      }
    };

    fetchTeamList();
  }, []);
  // console.log("skills", skillList);

  return (
    <Fragment>
      <Container component="main">
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
          }}
          className={classes.stepperContainer}
        >
          <div className={classes.containImageUpload}>
            <div
            // style={{
            //   background: "#EBECEE",
            //   padding: "0.4rem 1rem",
            //   borderRadius: 5,
            // }}
            >
              <img
                // src={
                //   imageUrl
                //     ? imageUrl
                //     : values.personalInfo?.profileImage
                //     ? values.personalInfo?.profileImage
                //     : USER_ICON
                // }
                src = {USER_ICON}
                alt="user"
                className={classes.userImage}
              />
              {/* {values.personalInfo.profileImage && (
                <CloseIcon
                  onClick={deleteProfileImage}
                  style={{ marginBottom: "6rem", cursor: "pointer" }}
                />
              )} */}

              {/* {values.personalInfo.profileImage && imageUrl !== USER_ICON ? (
                <CloseIcon
                  fontSize="small"
                  onClick={deleteProfileImage}
                  style={{ marginBottom: "6rem", cursor: "pointer" }}
                />
              ) : (
                ""
              )} */}
            </div>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: "20px",
              }}
            >
              <Button
                sx={{
                  width: 150,
                  height: 50,
                  background: "rgba(244, 121, 84, 0.12)",
                  color: "#F47954",
                  border: "none",
                  boxShadow: "none",
                  fontWeight: "bold",
                  fontSize: 15,
                  textTransform: "none",
                  "&:hover": {
                    background: "rgba(244, 121, 84, 0.12)",
                    boxShadow: "none",
                  },
                }}
                variant="contained"
                component="label"
                hidden
              >
                Upload Photo
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={(e) => handleImage(e)}
                  hidden
                />
              </Button>
            </div> */}
            <Fragment>
              <Field
                style={{ marginTop: "25px", marginLeft: "30px", width: "49%" }}
                label="Skills"
                multiple={true}
                name="skillId"
                component={FormikAutocomplete}
                fullWidth
                options={values?.skillId}
                disabled
                getOptionLabel={(option) => option?.skillName}
                ///getOptionLabel={values.personalInfo?.professionalSkills}
                // onChange={(e, value) => handleSkills(value)}
                // getOptionSelected={(option) =>
                //   values.skillId.find((data) => data._id === option._id)
                //     ? true
                //     : false
                // }
                // value={values?.skillId || []}

                getOptionSelected={(option, value) => {
                  return option?.skillName === value?.skillName;
                }}
              />
            </Fragment>
          </div>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <div style={{ width: "100%", marginRight: "1rem" }}>
              <h2 style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}>
                Full Name
              </h2>
              <FormikTextField
                fullWidth
                margin="normal"
                required
                className={classes.formInputField}
                label="Full Name "
                variant="outlined"
                name="personalInfo.fullName"
              />
            </div>
            <div style={{ width: "100%" }}>
              <h2 style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}>
                Job Title
              </h2>
              <FormikTextField
                margin="normal"
                required
                className={classes.formInputField}
                label="Job Title"
                fullWidth
                name="personalInfo.jobTitle"
              />
            </div>
          </Box>
          <div style={{ width: "100%" }}>
            <h2 style={{ color: "#7F8182", fontSize: "18px" }}>About Me*</h2>
            <Box sx={{ display: "flex", width: "100%" }}>
              <FormikTextField
                label="About Me"
                placeholder="Eg, I am a full Stack Developer"
                style={{ width: "100%" }}
                type="text"
                multiline
                rows={5}
                name="personalInfo.aboutMe"
              />
            </Box>
          </div>
        </Box>
      </Container>
    </Fragment>
  );
};

export default PersonalInformationForm;

// ROUTING CONSTANTS

// staging endpoint    ( test )
// export const BASE_URL = "http://resume.resourcifi.tech:4545";
// export const BASE_URL_WITHOUT_PORT = "http://resume.resourcifi.tech:4545";

// prod endpoint ( build )
export const BASE_URL = "https://resume.resourcifi.tech";
export const BASE_URL_WITHOUT_PORT = "https://resume.resourcifi.tech";

//Path route //
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const LOGIN_PATH = "/auth/login";
export const UPDATE_PASSWORD = "/user/update-password";
export const CREATE_CV = "/user/createCV";
export const PROFILE = "/user/profile";
export const ROOT_PATH = "/user";
export const FIRST_LOGIN_PATH = "/user/redirect/:userId";
export const SETTINGS_PATH = "/user/settings";
export const EMPLOYEES_PATH = "/user/employee";
export const DASHBOARD_PATH = "/user/dashboard";
export const RESUME_COMPLETE_PATH = "/user/resume/complete";
export const RESUME_EDIT_PATH = "/user/resume";
export const EDIT_RESUME_PATH = "/user/resume/edit";
export const RESUME_LIST_PATH = "/user/resume-list";

// ASSETS CONSTANTS
export const RESOURCIFI_LOGO = "/assets/logo/resourcifi.svg";
export const RESOURCIFI_CIRCULAR_LOGO = "/assets/logo/circular_logo.png";
export const USER_IMAGE = "/assets/images/userImage.png";
export const BACK_BTN = "/assets/images/backbtn.png";
export const USER_ICON = "/assets/images/user.svg";
export const BULB_IMAGE = "/assets/images/bulb.svg";
export const RESOURCIFI_IMAGE = "/assets/images/resourcifi_logo.png";
export const RESOURCIFI_DARK_IMAGE = "/assets/images/resourcifi_logo_dark.png";
export const ARROW_IMAGE = "/assets/images/arrow.svg";
export const RNF_IMAGE = "/assets/images/rnf_logo.png";
export const DASHBOARD_ICON = "/assets/icons/dashboard.svg";
export const EMPLOYEES_ICON = "/assets/icons/employees.svg";
export const ACCOUNTS_ICON = "/assets/icons/accounts.svg";
export const SETTINGS_ICON = "/assets/icons/settings.svg";
export const BELL_ICON = "/assets/icons/bell.svg";
export const DELETE_ICON = "/assets/icons/delete.svg";
export const DOWNLOAD_ICON = "/assets/icons/download.svg";
export const EDIT_ICON = "/assets/icons/edit.svg";
export const OPEN_ICON = "/assets/icons/open.svg";
export const EYE_ICON = "/assets/icons/eye.svg";
export const USER__ICON = "/assets/icons/usericon.svg";
export const ATTACH_ICON = "/assets/icons/attachment.svg";
export const COLOR_ATTACH_ICON = "/assets/icons/colorAttachment.svg";
export const CROSS_ICON = "/assets/icons/cross.svg";
export const ORANGE_STAR_ICON = "/assets/icons/orangeStar.svg";
export const GREY_STAR_ICON = "/assets/icons/greyStar.svg";
export const DONE_GIF = "/assets/logo/done.gif";

//DUMMY USER CONSTANTS

export const DUMMY_USER_NAME = "User Name";
export const DUMMY_USER_DESIGNATION = "Designation";
export const DUMMY_USER_ABOUT_ME = "Tell Us something about yourself.";

export const DUMMY_SCHOOL_NAME = "Institution Name";
export const DUMMY_DEGREE = "Qualification";
export const DUMMY_EDUCATION_DESCRIPTION = "Education Description";

export const DUMMY_ACHIEVEMENT_NAME = "achievement Name";
export const DUMMY_ACHIEVEMENT_DESCRIPTION = "achievement Description";

export const DUMMY_JOB_TITLE = "Job Profile";
export const DUMMY_EMPLOYEER = "Employeer";
export const DUMMY_EXPERIENCE_DESCRIPTION = "Work Experience";

export const DUMMY_PROJECT_NAME = "Project name";
export const DUMMY_PROJECT_TECHNOLOGY = "Technology used";
export const DUMMY_PROJECT_DESCRIPTION = "Project description";

export const DUMMY_PROFESSIONAL_SKILL = "skill";
export const DUMMY_PERSONAL_SKILL = "skill";

export const DUMMY_HOBBY = "Hobby";

//MAX FILE SIZE CONSTANT
export const MAX_FILE_SIZE = 512000; // 512*1024

// Multiplier Secret
export const MULTIPILIER = 91;

// Crypto Secret
export const CRYPTO_SECRET = "wzvipek";

// Preview Redirect
export const PREVIEW_IN_BETWEEN = "profile";

export const DAYS_7 = 30 * 24 * 60 * 60 * 1000;

// Years
// export const PROJECT_YEARS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
// export const PROJECT_MONTHS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const PROJECT_YEARS = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];
export const PROJECT_MONTHS = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
];
export const REFERENCE_LIST = ["Ios", "Android", "Website", "Web app"];

export const MONTHS_NAME = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const YEARS = [
  "2025",
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
];

import React, { useState } from "react";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { Container } from "@mui/material";
import axios from "axios";
import { BASE_URL, RESOURCIFI_LOGO } from "../../utils/constants/constants";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router";
import { styles } from "../../styles/styles";
import Spacer from "../../components/Spacer/Spacer";
import Visibility from "@mui/icons-material/Visibility";
import { VisibilityOff } from "@mui/icons-material";
import { passwordValidation } from "../../utils/validations/inputValidations";

const ResetPassword = ({ email }) => {
  const classes = styles();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordsVisibible, setPasswordsVisible] = React.useState({
    pass: false,
    confPass: false,
  });

  //RESET PASSWORD FUNCTION
  const handleResetPassword = (e) => {
    e.preventDefault();
    const validate = passwordValidation(password);
    if (!validate) {
      CustomEventEmitter.emit(
        "alert_error",
        "Password should have 8 Charecters, 1 Number and 1 Special Charecter"
      );
      return;
    }
    setLoading(true);
    axios
      .put(`${BASE_URL}/api/user/reset/password`, {
        email: email,
        newPassword: password,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          CustomEventEmitter.emit("alert_success", response.data.message);
          navigate(-2);
        }
        CustomEventEmitter.emit("alert_error", response.data.message);
      })
      .catch((error) => {
        CustomEventEmitter.emit(
          "alert_error",
          error?.response?.data?.message || "Unknown or Network Error"
        );
      })
      .finally(() => {
        setLoading(false);
        setPassword("");
        setConfirmPassword("");
      });
  };

  const handleClickShowPassword = (type) => {
    // console.log('type', type, passwordsVisibible)
    setPasswordsVisible({
      ...passwordsVisibible,
      [type]: !passwordsVisibible[type],
    });
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs" className={classes.root1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className={classes.loginContainer}
        >
          <div>
            <img
              className={classes.resourcifiLogo}
              src={RESOURCIFI_LOGO}
              alt="logo"
            />
          </div>
          <Spacer height={10} />

          <Typography>
            <p className={classes.signInHead}>Create New Password</p>
          </Typography>
          <Box
            component="form"
            onSubmit={handleResetPassword}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="New Password"
              autoFocus
              type={passwordsVisibible.pass ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.inputField}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("pass")}
                    >
                      {passwordsVisibible.pass ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              type={passwordsVisibible.confPass ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={classes.inputField}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("confPass")}
                    >
                      {passwordsVisibible.confPass ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {loading ? (
              <Loader />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className={classes.actionBtnWitMargin}
                disabled={
                  !(confirmPassword.length && password === confirmPassword)
                }
              >
                {" "}
                Set New Password{" "}
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ResetPassword;

import './App.css';
import {SnackbarProvider } from "notistack";
import { ThemeProvider } from '@mui/private-theming';
import { createTheme } from '@mui/material';
import Router from './App/Router/Router';
const config = {
  snackBarPlacement: {
    vertical: "bottom",
    horizontal: "right",
  },
};

const theme = createTheme({
  components: {
    MuiTextField: {
      variants: [
        {
          style: {
            textTransform: 'none',
          },
        },
        {
          style: {
            border:'green'
          },
        },
      ],
    },
  },
})

function App() {

  return (
    <ThemeProvider theme={theme} >
      <SnackbarProvider anchorOrigin={config.snackBarPlacement} autoHideDuration={2000} >
        <div className="App">
          <Router />
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;

import { CircularProgress } from '@mui/material';
import React from 'react';
import Spacer from '../Spacer/Spacer';
import { styles } from '../../styles/styles';

const MainLoader = () => {

    const classes = styles();

    return <React.Fragment>
            <Spacer />
            <CircularProgress className={ classes.loader } />
        </React.Fragment>
}

export default MainLoader;
import React, { Fragment, useEffect, useState } from "react";
import { checkLoggedIn } from "../../api/Auth";
import { CssBaseline } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { styles } from "../../styles/styles";
import { useNavigate } from "react-router";
import { isResumeEligible } from "../../utils/helper/checkDesignation";
import {
  EMPLOYEES_PATH,
  LOGIN_PATH,
  PROFILE,
  RESOURCIFI_LOGO,
  ROOT_PATH,
  USER__ICON,
} from "../../utils/constants/constants";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "white",
  boxShadow: "none",
  borderBottom: "1px solid #cccccc",
  ...(open && {
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  }),
}));

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const classes = styles();
  const [loggedIn, setLoggedIn] = useState(true);
  const [name, setName] = useState("");
  const [user, setUser] = useState();

  const handleSignOut = () => {
    localStorage.clear();
    navigate(LOGIN_PATH);
  };

  useEffect(() => {
    const isLoggedIn = checkLoggedIn();
    isLoggedIn ? setLoggedIn(true) : setLoggedIn(false);
    if (isLoggedIn) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      setName(user.name);
    } else {
      navigate(LOGIN_PATH);
    }
  }, [loggedIn, navigate]);

  const handleNavigation = () => {
    const validate = isResumeEligible(user.userType);
    if (validate) {
      navigate(ROOT_PATH);
    } else {
      navigate(EMPLOYEES_PATH);
    }
  };

  return (
    <Fragment>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={true}>
          <Toolbar>
            <img
              onClick={handleNavigation}
              src={RESOURCIFI_LOGO}
              className={classes.logo}
              alt="logo"
            />
          </Toolbar>
          <Toolbar></Toolbar>
          <Toolbar>
            <div
              className={classes.usercontain}
              onClick={() => navigate(PROFILE)}
            >
              <img
                src={USER__ICON}
                style={{ width: 20, height: 20, marginRight: 10 }}
                alt=""
              />
              <p
                style={{
                  color: "#7F8182",
                  fontWeight: "bold",
                  fontSize: "16px",
                  margin: 0,
                }}
              >
                {name}
              </p>
            </div>
            <button className={classes.logoutbtn} onClick={handleSignOut}>
              Logout
            </button>
          </Toolbar>
        </AppBar>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </Fragment>
  );
};

export default DashboardLayout;

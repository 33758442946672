import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styles } from "../../styles/styles";
import {
  BASE_URL,
  BASE_URL_WITHOUT_PORT,
  COLOR_ATTACH_ICON,
  DOWNLOAD_ICON,
  EDIT_ICON,
  PREVIEW_IN_BETWEEN,
} from "../../utils/constants/constants";
import { Box } from "@mui/system";
import axios from "axios";
import SmallLoader from "../Loader/SmallLoader";
import { Tooltip } from "@mui/material";
import { Encyption, expiryTime } from "../../utils/helper/crypto";

const CommanTable = (props) => {
  const classes = styles();
  const { columns, rowsPerPage, setRowsPerPage, rows } = props;
  const [page, setPage] = useState(0);
  const [downloading, setDownloading] = useState("");
  const [copyStatus, setCopyStatus] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // console.log("table data", rows);
  const filterValue = (data) => {
    if (Array.isArray(data)) {
      let res = data.map((value) => {
        let result = "";
        result = result.concat(value.teamName + " ");
        return result;
      });

      return res.join(",");
    }
    if (data?.userType) {
      return data.userType;
    }
    return data;
  };

  const handleDownload = (data) => {
    if (data?.resumeStatus === "Complete") {
      setDownloading(data._id);
      const user = JSON.parse(localStorage.getItem("user"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      axios
        .get(`${BASE_URL}/api/resume-detail/pdf/download/${data._id}`, config)
        .then((response) => {
          if (response?.data?.data?.pdfLink?.Location) {
            const a = document.createElement("a");
            a.href = response?.data?.data?.pdfLink?.Location;
            a.target = "_blank";
            a.download = `${data?.firstName} ${data?.lastName} - resume.pdf`;
            a.click();
          }
        })
        .catch((error) => {
          // console.log("érror", error.response);
        })
        .finally(() => {
          setDownloading("");
        });
    }
  };

  const handleRedirect = (row) => {
    const a = document.createElement("a");
    a.href = `/user/resume/edit/${row._id}`;
    localStorage.setItem("userEmpId", row.employeeId);
    a.target = "_blank";
    a.click();
  };

  const handleCopy = (data) => {
    // console.log("data", data);
    const ciphertext = Encyption(data.employeeId);
    const encodedTime = expiryTime();
    const previewURL = `${BASE_URL_WITHOUT_PORT}/${PREVIEW_IN_BETWEEN}/${ciphertext}`;
    setCopyStatus(data._id);
    navigator.clipboard.writeText(previewURL);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      <TableContainer className={classes.loginContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableColumns}
                >
                  {column.label}
                </TableCell>
              ))}

              <TableCell className={classes.tableColumns}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      {columns.map((column) => {
                        if (column.id === "sno") {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={classes.tableRows}
                            >
                              {index + 1}
                            </TableCell>
                          );
                        } else {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={classes.tableRows}
                            >
                              <span
                                style={{
                                  color: `${
                                    column.id === "resumeStatus"
                                      ? filterValue(value) === "Complete"
                                        ? "green"
                                        : "#F3C607"
                                      : "black"
                                  }`,
                                }}
                              >
                                {filterValue(value)}
                              </span>
                            </TableCell>
                          );
                        }
                      })}
                      <TableCell>
                        {row.resumeStatus === "Complete" ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Tooltip
                              title={
                                copyStatus.length && copyStatus === row._id
                                  ? "Link Copied Successfully"
                                  : "Copy Link"
                              }
                            >
                              <img
                                src={COLOR_ATTACH_ICON}
                                alt=""
                                className={classes.icon}
                                onClick={(e) => handleCopy(row)}
                              />
                            </Tooltip>
                            {downloading.length && downloading === row._id ? (
                              <SmallLoader />
                            ) : (
                              <img
                                src={DOWNLOAD_ICON}
                                alt=""
                                className={classes.icon}
                                onClick={() => handleDownload(row)}
                              />
                            )}
                            <img
                              onClick={() => handleRedirect(row)}
                              src={EDIT_ICON}
                              alt=""
                              className={classes.icon}
                            />
                          </Box>
                        ) : (
                          <p style={{ color: "red" }}>
                            Resume status is pending
                          </p>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>No Records Found</TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={rows?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CommanTable;

import React, { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  LOGIN_PATH,
  RESUME_EDIT_PATH,
  RESUME_LIST_PATH,
} from "../../utils/constants/constants";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import { checkLoggedIn } from "../../api/Auth";
import { styles } from "../../styles/styles";
import { passwordValidation } from "../../utils/validations/inputValidations";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import { VisibilityOff } from "@mui/icons-material";

export default function UpdatePassword() {
  const classes = styles();
  const navigate = useNavigate();
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [passwordsVisibible, setPasswordsVisible] = React.useState({
    oldPass: false,
    pass: false,
    confPass: false,
  });

  React.useEffect(() => {
    const isLoggedIn = checkLoggedIn();
    if (!isLoggedIn) {
      navigate(LOGIN_PATH);
    } else {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, [navigate]);

  //Handle Reset Password
  const handleUpdatePassword = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const oldPassword = data.get("oldPassword");
    const newPassword = data.get("newPassword");
    const confirmPassword = data.get("confirmPassword");

    const validate = passwordValidation(newPassword);
    // console.log("validate");
    if (!validate) {
      CustomEventEmitter.emit(
        "alert_error",
        "Password should have 8 Charecters, 1 Number and 1 Special Charecter"
      );
      return;
    }

    if (!(oldPassword.length && newPassword.length && confirmPassword.length)) {
      CustomEventEmitter.emit("alert_error", "All Fields are mandatory");
      return;
    }

    if (newPassword !== confirmPassword) {
      CustomEventEmitter.emit(
        "alert_error",
        "New Password and confirm password should be same"
      );

      return;
    }
    setLoading(true);

    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .put(
        `${BASE_URL}/api/user/update/password`,
        { oldPassword, newPassword },
        config
      )
      .then((response) => {
        setLoading(false);
        if (response.data.statusCode === 200) {
          CustomEventEmitter.emit(
            "alert_success",
            "password updated successfully"
          );
          navigate(RESUME_LIST_PATH);
        }
      })
      .catch((error) => {
        CustomEventEmitter.emit(
          "alert_error",
          `${error?.response?.data?.message || "Network or unknown error"}`
        );

        setLoading(false);
      });
  };

  const handleClickShowPassword = (type) => {
    // console.log("type", type, passwordsVisibible);
    setPasswordsVisible({
      ...passwordsVisibible,
      [type]: !passwordsVisibible[type],
    });
  };

  return (
    <Fragment>
      <Container
        component="main"
        maxWidth="sm"
        className={classes.updatePasswordContainer}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
            // minHeight: "85vh",
          }}
          className={classes.loginContainer}
        >
          <Typography>
            <p className={classes.signInHead}>Update Your Password</p>
          </Typography>
          <Box
            component="form"
            onSubmit={handleUpdatePassword}
            noValidate
            className={classes.inputFieldContainer}
          >
            <TextField
              margin="normal"
              required
              className={classes.inputField}
              label="Old Password"
              name="oldPassword"
              autoFocus
              type={passwordsVisibible.oldPass ? "text" : "password"}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("oldPass")}
                    >
                      {passwordsVisibible.oldPass ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              className={classes.inputField}
              label="New Password"
              name="newPassword"
              type={passwordsVisibible.pass ? "text" : "password"}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("pass")}
                    >
                      {passwordsVisibible.pass ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin="normal"
              required
              className={classes.inputField}
              label="Confirm New Password"
              name="confirmPassword"
              type={passwordsVisibible.confPass ? "text" : "password"}
              sx={{ mb: 2 }}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("confPass")}
                    >
                      {passwordsVisibible.confPass ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {loading ? (
              <div style={{ width: "50%" }}>
                <Loader alignment={"center"} />
              </div>
            ) : (
              <button
                type="submit"
                variant="contained"
                className={classes.resetPasswordBtn}
              >
                Reset Password
              </button>
            )}
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
}

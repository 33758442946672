import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useNavigate} from 'react-router-dom';
import { RESOURCIFI_LOGO, ROOT_PATH , BASE_URL} from '../../utils/constants/constants';
import Loader from '../../components/Loader/Loader';
import Spacer from '../../components/Spacer/Spacer';
import axios from 'axios';
import ResetPassword from '../ResetPassword/ResetPassword';
import CustomEventEmitter from '../../utils/emitter/EventEmitter';
import { checkLoggedIn } from '../../api/Auth';
import { styles } from '../../styles/styles';

 
export default function ForgotPassword() {
    
  const classes = styles();
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false);
  const [variant, setVariant] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [changing, setChanging] = React.useState(false);
  const [otpVerified, setOtpVerified] = React.useState(false)

  React.useEffect(()=>{
    const isLoggedIn = checkLoggedIn();
    if(isLoggedIn){
        navigate( ROOT_PATH );
    }
},[navigate]);


    //PASSWORD FORGOT FUNCTION
    const handleForgotPassword = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    if(!email.length){
      return 
    }
    setLoading(true);
    axios.post(`${BASE_URL}/api/user/forgot/password`,{ email:email }).then((response)=>{
      if(response.data.statusCode===200){
        setVariant('success');
        CustomEventEmitter.emit(`alert_success`,response.data.message);
        setLoading(false);
        setEmail(email);
      }else{
        CustomEventEmitter.emit(`alert_error`,response.data.message);
        setLoading(false);
        setEmail(email);
      }
    }).catch((error)=>{
      setVariant('error');
      CustomEventEmitter.emit(`alert_error`,error?.response?.data?.message || "Unknown or network error");
      
      setLoading(false);
    })
  };


  //Change Password Function
  const handleVerifyOtp = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    const otp = data.get('otp');


    setChanging(true);
    axios.post(`${BASE_URL}/api/user/otp/verify`,{ email, otp }).then((response)=>{
      
      if(response.data.statusCode===200){
        setChanging(false);
        CustomEventEmitter.emit(`alert_success`,response?.data?.message || "Password changed");
        setOtpVerified(true);
      }else{
        setChanging(false);
        CustomEventEmitter.emit(`alert_error`,response?.data?.message || "Unknown or network error");

      }
      
    }).catch((error)=>{
      setOtpVerified(false)
      CustomEventEmitter.emit(`alert_error`,error?.response?.data?.message || "Unknown or network error");
      setChanging(false);

    })

  } 


  return (
      <React.Fragment>
      {/* <div className="back-btn-container" >
          <Button onClick={()=> navigate(-1) } variant="contained" ><i className="fas fa-arrow-left"></i>Back</Button>
      </div> */}
      { otpVerified ? <ResetPassword email={email}/> : 
      <Container component="main" maxWidth="xs" className={ classes.root1 } >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className={ classes.loginContainer1 }
        >

          <div>
            <img className={ classes.resourcifiLogo } src={ RESOURCIFI_LOGO }  alt="logo" />
          </div>
          <Spacer height={10} />
          
          <Typography >
            <p className={ classes.signInHead } >
            Forgot Password
            </p>
          </Typography>
          <Box component="form" onSubmit={variant==='success' ? handleVerifyOtp : handleForgotPassword} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth={true}
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              disabled={variant==='success'}
            />
            { variant==='success' ? <div>
              <TextField
                margin="normal"
                required
                fullWidth
                label="OTP recieved on mail"
                name="otp"
                autoComplete="number"
                autoFocus
                type="number"

              />
            </div> : null }
            { loading ? <Loader /> : changing ? <Loader /> : <Button
              type="submit"
              fullWidth
              variant="contained"
              className={ classes.actionBtnWitMargin }

            >
              { variant==='success' ? 'Verify OTP' : 'Send Email' }
              
            </Button> }
          
            
          </Box>
        </Box>
      </Container> }
    </React.Fragment> 
  );
}
import React, { Fragment, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { styles } from "../../styles/styles";
import Box from "@mui/material/Box";
import { Field } from "formik";
import { FormikAutocomplete, FormikTextField } from "../FormControlsWithFormik";
import { DELETE_ICON } from "../../utils/constants/constants";
import Collapsible from "react-collapsible";
import CollapsableElement from "../CollapsableElement/CollapsableElement";
const SkillsInfoForm = (props) => {
  const { values } = props;
  const classes = styles();
  const [skills, setSkills] = useState([{ skill: "", level: "1" }]);
  const [personalSkills, setPersonalSkills] = useState([
    { skill: "", level: "1" },
  ]);
  const levels = ["1", "2", "3", "4", "5"];
  const [flag, setFlag] = useState(true);

  const handleAddMore = (e) => {
    e.preventDefault();
    // const lastValue = skills[skills.length - 1];
    if (
      values.professionalSkills[values.professionalSkills.length - 1]?.skill &&
      skills.length === values.professionalSkills.length
    ) {
      setSkills([...skills, { skill: "", level: "1" }]);
    }
  };

  const handleAddMorePersonal = (e) => {
    e.preventDefault();

    // const previousValue = personalSkills[personalSkills.length - 1];
    if (
      values.personalSkills[values.personalSkills.length - 1]?.skill &&
      personalSkills.length === values.personalSkills.length
    ) {
      setPersonalSkills([...personalSkills, { skill: "", level: "1" }]);
    }
  };

  useEffect(() => {
    if (values?.professionalSkills?.length) {
      setSkills(values.professionalSkills);
    }
    if (values?.personalSkills?.length) {
      setPersonalSkills(values.personalSkills);
    }
  }, [flag]);

  const handleDelete = (index) => {
    setSkills([]);
    values.professionalSkills.splice(index, 1);
    props.formikProps.setFieldValue(
      "professionalSkills",
      values.professionalSkills
    );
    flag ? setFlag(false) : setFlag(true);
  };

  const handleDeletePeresonal = (index) => {
    setPersonalSkills([]);
    values.personalSkills.splice(index, 1);
    props.formikProps.setFieldValue("personalSkills", values.personalSkills);
    console.log("personalSkills", values.personalSkills);
    flag ? setFlag(false) : setFlag(true);
  };

  return (
    <Fragment>
      <Container component="main" className={classes.updatePasswordContainer}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
          }}
          className={classes.stepperContainer}
        >
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h4
                style={{
                  textAlign: "left",
                  margin: 0,
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Skills
              </h4>
              <p>Add your skill that you most experienced at</p>
            </div>
          </Box>
          {skills &&
            skills.map((skill, index) => {
              return (
                <Box
                  key={skill.skill}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <div style={{ width: "100%", marginRight: "1rem" }}>
                    <h2
                      style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}
                    >
                      Skill*
                    </h2>
                    <FormikTextField
                      fullWidth
                      margin="normal"
                      required
                      label="Eg. Python"
                      variant="outlined"
                      name={`professionalSkills[${index}].skill`}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <h2
                      style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}
                    >
                      Level*
                    </h2>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Field
                        name={`professionalSkills[${index}].level`}
                        sx={{ width: "280px", mt: 2 }}
                        fullWidth
                        label={"Select your skill level"}
                        component={FormikAutocomplete}
                        options={levels}
                        textFieldProps={{
                          variant: "outlined",
                        }}
                        size="medium"
                        required
                      />
                      {index >= 1 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 5,
                            marginLeft: 10,
                            justifyContent: "center",
                          }}
                        >
                          <img
                            onClick={() => handleDelete(index)}
                            src={DELETE_ICON}
                            style={{ width: "20px", height: "20px" }}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              );
            })}

          <Box
            sx={{
              alignItems: "right",
              textAlign: "right",
              margin: "1rem 2rem",
            }}
          >
            <button className={classes.logoutbtn} onClick={handleAddMore}>
              Add More
            </button>
          </Box>
        </Box>
        <Box>
          <Collapsible
            trigger={
              <CollapsableElement
                message="Add your personal skills"
                icon="open"
              />
            }
            triggerWhenOpen={
              <CollapsableElement
                message="Add your personal skills"
                icon="close"
              />
            }
          >
            <Box
              sx={{
                display: "flex",
                flex: "1 1 auto",
                flexDirection: "column",
                width: "100%",
                backgroundColor: "white",
              }}
              className={classes.stepperContainer}
            >
              {personalSkills &&
                personalSkills.map((skill, index) => {
                  return (
                    <Box
                      key={skill.skill}
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <div style={{ width: "100%", marginRight: "1rem" }}>
                        <h2
                          style={{
                            color: "#7F8182",
                            fontSize: "18px",
                            margin: 0,
                          }}
                        >
                          Skill*
                        </h2>
                        <FormikTextField
                          fullWidth
                          margin="normal"
                          required
                          label="Eg. Team work"
                          variant="outlined"
                          name={`personalSkills[${index}].skill`}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <h2
                          style={{
                            color: "#7F8182",
                            fontSize: "18px",
                            margin: 0,
                          }}
                        >
                          Level*
                        </h2>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Field
                            name={`personalSkills[${index}].level`}
                            sx={{ width: "280px", mt: 2 }}
                            fullWidth
                            label={"Select your skill level"}
                            component={FormikAutocomplete}
                            options={levels}
                            textFieldProps={{
                              variant: "outlined",
                            }}
                            size="medium"
                            required
                          />
                          {index >= 1 && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 5,
                                marginLeft: 10,
                                justifyContent: "center",
                              }}
                            >
                              <img
                                onClick={() => handleDeletePeresonal(index)}
                                src={DELETE_ICON}
                                style={{ width: "20px", height: "20px" }}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Box>
                  );
                })}

              <Box
                sx={{
                  alignItems: "right",
                  textAlign: "right",
                  margin: "1rem 2rem",
                }}
              >
                <button
                  className={classes.logoutbtn}
                  onClick={handleAddMorePersonal}
                >
                  Add More
                </button>
              </Box>
            </Box>
          </Collapsible>
        </Box>
      </Container>
    </Fragment>
  );
};

export default SkillsInfoForm;

import React, { Fragment ,useEffect} from 'react';
import { checkLoggedIn } from '../../api/Auth';
import { useNavigate } from 'react-router';
import { LOGIN_PATH, ROOT_PATH } from '../../utils/constants/constants';

const HomeScreen = ( ) => {

    const navigate = useNavigate();

    useEffect(()=>{
        const isLoggedIn = checkLoggedIn();
        isLoggedIn ? navigate( ROOT_PATH ) : navigate( LOGIN_PATH )
    },[navigate])

    return <Fragment>

    </Fragment>
}

export default HomeScreen;
import React, { Fragment, useState } from "react";
import "./Login.css";
import TextField from "@mui/material/TextField";
import { InputAdornment, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  BASE_URL,
  BASE_URL_WITHOUT_PORT,
  EMPLOYEES_PATH,
  FORGOT_PASSWORD,
  PREVIEW_IN_BETWEEN,
  RESOURCIFI_LOGO,
  RESUME_EDIT_PATH,
  RESUME_LIST_PATH,
  ROOT_PATH,
  UPDATE_PASSWORD,
} from "../../utils/constants/constants";
import Spacer from "../../components/Spacer/Spacer";
import Loader from "../../components/Loader/Loader";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import { checkLoggedIn } from "../../api/Auth";
import { styles } from "../../styles/styles";
import Visibility from "@mui/icons-material/Visibility";
import { VisibilityOff } from "@mui/icons-material";
import { passwordValidation } from "../../utils/validations/inputValidations";
import { Encyption, expiryTime } from "../../utils/helper/crypto";
import { getResumeList } from "../../api/api";

function Login() {
  const classes = styles();
  const Navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  React.useEffect(() => {
    const isLoggedIn = checkLoggedIn();
    if (isLoggedIn) {
      Navigate(ROOT_PATH);
    }
  }, [Navigate]);

  //Login User
  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    if (email && password) {
      const validate = passwordValidation(password);
      if (!validate) {
        CustomEventEmitter.emit(
          "alert_error",
          "Password should have 8 Charecters, 1 Number and 1 Special Charecter"
        );
        return;
      }
      setLoading(true);

      //Axios Post Request
      axios
        .post(`${BASE_URL}/api/user/login`, {
          email: email,
          password: password,
        })
        .then((response) => {
          if (response.status === 201 && response.data.statusCode === 200) {
            // console.log(
            // "response in login",
            // response?.data?.data?.user?.userTypeId?.userType
            // );
            CustomEventEmitter.emit("alert_success", "Logged in successfully");
            const userType = response?.data?.data?.user?.userTypeId?.userType;
            localStorage.setItem(
              "user",
              JSON.stringify({
                id: response?.data?.data?.user?._id,
                name:
                  response?.data?.data?.user?.firstName +
                  " " +
                  response?.data?.data?.user?.lastName,
                email: response.data.data.user.email,
                token: response.data.data.token,
                userType,
                employeeId: response?.data?.data?.user?.employeeId,
              })
            );
            setTimeout(async () => {
              if (response?.data?.data?.user?.isNewUser) {
                Navigate(UPDATE_PASSWORD);
              } else {
                if (userType === "Sales Person") {
                  Navigate(EMPLOYEES_PATH);
                } else {
                  // const cipherText = Encyption(
                  //   response?.data?.data?.user?.employeeId
                  // );
                  // console.log("response123", response);
                  // const encodedTime = expiryTime();
                  Navigate(RESUME_LIST_PATH);

                  // const resumeList = `${BASE_URL}/api/resume-detail/user/resume/list`;
                  // //if()
                  // axios
                  //   .get(previewURL)
                  //   .then(() => {
                  //     Navigate(ROOT_PATH);
                  //   })
                  //   .catch((error) => {
                  //     // console.log(error, "Login error");
                  //     Navigate(RESUME_EDIT_PATH);
                  //   })
                  //   .finally(() => {
                  //     setLoading(false);
                  //   });
                }
              }
            }, 100);
          }
        })
        .catch((error) => {
          CustomEventEmitter.emit(
            "alert_error",
            `${
              error?.response?.data?.message ||
              "Login Failed, unknown or network error"
            }`
          );
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Container className={classes.root1} component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className={classes.loginContainer1}
        >
          <div>
            <img
              className={classes.resourcifiLogo}
              src={RESOURCIFI_LOGO}
              alt="logo"
            />
          </div>
          <Spacer height={10} />
          <Typography>
            <p className={classes.signInHead}>Sign in</p>
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address/ EMP ID"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.forgotPasswordContainer}>
              <Link to={FORGOT_PASSWORD} className={classes.forgotPasswordBtn}>
                Forgot Password?
              </Link>
              <Spacer height={40} />
            </div>
            {loading ? (
              <Loader />
            ) : (
              <button type="submit" className={classes.SignInBtn}>
                Sign In
              </button>
            )}
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
}

export default Login;

import React, { Fragment } from "react";
import { Box } from "@mui/system";
import {
  GREY_STAR_ICON,
  ORANGE_STAR_ICON,
  USER_ICON,
  DUMMY_USER_NAME,
  DUMMY_USER_DESIGNATION,
  DUMMY_USER_ABOUT_ME,
  DUMMY_DEGREE,
  DUMMY_SCHOOL_NAME,
  DUMMY_EDUCATION_DESCRIPTION,
  DUMMY_JOB_TITLE,
  DUMMY_EMPLOYEER,
  DUMMY_EXPERIENCE_DESCRIPTION,
  DUMMY_PROJECT_NAME,
  DUMMY_PROJECT_TECHNOLOGY,
  DUMMY_PROJECT_DESCRIPTION,
  DUMMY_PERSONAL_SKILL,
  DUMMY_PROFESSIONAL_SKILL,
  DUMMY_HOBBY,
  RESOURCIFI_DARK_IMAGE,
  RNF_IMAGE,
  DUMMY_ACHIEVEMENT_NAME,
  DUMMY_ACHIEVEMENT_DESCRIPTION,
} from "../../utils/constants/constants";
import { makeStyles } from "@mui/styles";
import Footer from "./Footer";

const styles = makeStyles({
  containLogoHeading: {
    background: "rgba(232, 232, 232, 0.11)",
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid rgba(185, 185, 185, 1)",
    borderTop: "1px solid rgba(185, 185, 185, 1)",
  },
  headImage: {
    height: 20,
    paddingLeft: "1rem",
  },
  rnfheadImage: {
    height: 20,
    paddingRight: "1rem",
    borderRight: "1px solid rgba(185, 185, 185, 1)",
  },
  mainContainer: {
    paddingTop: "2rem",
  },
  userImage: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    objectFit: "contain",
    padding: "4px",
  },
  containImage: {
    width: "100px",
    height: "100px",
    border: "3px solid #F47954",
    borderRadius: "12px",
  },
  eachBox: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    padding: "2rem 0.5rem",
    paddingTop: "0",
  },
  eachBoxFirst: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    padding: "2rem 0.5rem",
  },
  containData: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: "12px",
  },
  h1: {
    margin: 0,
    fontSize: "30px",
    textAlign: "left",
    fontWeight: "bold",
    lineHeight: 1,
  },
  h6: {
    margin: 0,
    fontSize: "13px",
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
  },
  h6Light: {
    margin: 0,
    fontSize: "13px",
    textAlign: "left",
    color: "black",
    fontWeight: "100",
    Width: "40px",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "12px !important",
    margin: 0,
    borderBottom: "3px solid #F47954",
    textAlign: "left",
  },
  h4: {
    margin: 0,
    fontSize: "16px",
    textAlign: "left",
  },
  containSubTitle: {
    padding: "5px",
  },
  para: {
    margin: 0,
    textAlign: "left",
    fontSize: "14px",
  },
  border: {
    borderTop: "2px solid #F47954",
    margin: "1rem",
  },
  hr: {
    margin: 0,
  },
  containEducationData: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    paddingBottom: "0.8rem",
  },
  educationH4: {
    margin: 0,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#F47954",
  },
  educationPara: {
    margin: 0,
    fontSize: "14px",
    textAlign: "left",
  },
  achievementEducationPara: {
    margin: 0,
    fontSize: "14px",
    textAlign: "left",
  },
  containEducation: {
    paddingTop: "0.8rem",
  },
  containStars: {
    display: "flex",
  },
  containStarData: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0.4rem",
  },
  containHobby: {
    display: "flex",
    flexWrap: "wrap",
  },
  eachHobbie: {
    background: "rgba(244, 121, 84, 0.22)",
    width: "100px",
    margin: "10px",
    borderRadius: "10px",
  },
  star: {
    width: 15,
    height: "15px",
  },
  refLink: {
    textDecoration: "none",
    color: "#F47954",
    fontSize: "14px",
    fontWeight: "bold",
    alignSelf: "center",
    paddingLeft: 2,
  },
  refLinkText: {
    margin: "0.05rem 0.3rem",
    marginLeft: "0",
    fontSize: "14px",
  },
  containLink: {
    display: "flex",
  },
});

const RealTimeTemplate = ({ values }) => {
  // console.log("values", values);
  const classes = styles();

  return (
    <Fragment>
      <Box
        className="real-time-template-scrolling"
        sx={{
          width: "31%",
          height: "85vh",
          overflowY: "scroll",
          background: "white",
          position: "fixed",
        }}
      >
        <Box className={classes.containLogoHeading}>
          <img src={RNF_IMAGE} alt="logo" className={classes.rnfheadImage} />
          <img
            src={RESOURCIFI_DARK_IMAGE}
            alt="logo"
            className={classes.headImage}
          />
        </Box>
        <Box className={classes.mainContainer}>
          <Box className={classes.eachBox}>
            <Box className={classes.containImage}>
              <img
                alt=""
                // src={
                //   values?.personalInfo?.profileImage
                //     ? values?.personalInfo?.profileImage
                //     : USER_ICON
                // }
                src = {USER_ICON}
                className={classes.userImage}
              />
            </Box>
            <Box className={classes.containData}>
              <h6 className={classes.h6}>Hello I'm</h6>
              <h1 className={classes.h1}>
                {values?.personalInfo?.fullName
                  ? values?.personalInfo?.fullName
                  : DUMMY_USER_NAME}
              </h1>
              <h4 className={classes.h4}>
                {values?.personalInfo?.jobTitle
                  ? values?.personalInfo?.jobTitle
                  : DUMMY_USER_DESIGNATION}
              </h4>
            </Box>
          </Box>
          <hr className={classes.hr} />
          <Box className={classes.eachBoxFirst}>
            <Box className={classes.containSubTitle}>
              <h3 className={classes.subTitle}>About Me</h3>
            </Box>
            <Box className={classes.containData}>
              <p className={classes.para} style={{ whiteSpace: "pre-wrap" }}>
                {values?.personalInfo?.aboutMe
                  ? values.personalInfo.aboutMe
                  : DUMMY_USER_ABOUT_ME}
              </p>
            </Box>
          </Box>

          <Box className={classes.eachBox}>
            <Box className={classes.containSubTitle}>
              <h3 className={classes.subTitle}>Education Information</h3>
            </Box>
            <Box className={classes.border}>
              {values?.educationInfo?.map((item) => {
                return (
                  <Box key={item._id} className={classes.containEducation}>
                    <Box className={classes.containEducationData}>
                      <h4 className={classes.h6}>
                        {isNaN(new Date(item?.startDate).getFullYear())
                          ? "YYYY"
                          : new Date(item?.startDate).getFullYear()}{" "}
                        -{" "}
                        {isNaN(new Date(item?.startDate).getFullYear())
                          ? "YYYY"
                          : new Date(item?.endDate).getFullYear()}
                      </h4>
                      <h4 className={classes.h6}>
                        {item?.degree ? item?.degree : DUMMY_DEGREE}{" "}
                      </h4>
                    </Box>
                    <Box className={classes.containEducationData}>
                      <h4 className={classes.educationH4}>
                        {item?.college ? item?.college : DUMMY_SCHOOL_NAME}
                      </h4>

                      <p className={classes.educationPara}>
                        {item.isBulletin
                          ? item?.desc.split("\n").map((item, index) => {
                              return <li key={index}>{item}</li>;
                            })
                          : item?.desc
                          ? item?.desc
                          : DUMMY_EDUCATION_DESCRIPTION}
                      </p>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box className={classes.eachBox}>
            <Box className={classes.containSubTitle}>
              <h3 className={classes.subTitle}>Academic Achievement</h3>
            </Box>
            <Box className={classes.border}>
              {values?.achievement.length !== 0 ? (
                values?.achievement?.map((item) => {
                  return (
                    <Box key={item._id} className={classes.containEducation}>
                      <Box className={classes.containEducationData}>
                        <h4 className={classes.h6}>
                          {isNaN(new Date(item?.completionYear).getFullYear())
                            ? "YYYY"
                            : new Date(item?.completionYear).getFullYear()}{" "}
                        </h4>
                        <h4 className={classes.h6}>
                          {item?.achievementName
                            ? item?.achievementName
                            : DUMMY_ACHIEVEMENT_NAME}{" "}
                        </h4>
                      </Box>
                      <Box className={classes.containEducationData}>
                        <div></div>
                        <p className={classes.achievementEducationPara}>
                          {item?.desc
                            ? item?.desc
                            : DUMMY_ACHIEVEMENT_DESCRIPTION}
                        </p>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <></>
              )}
            </Box>
          </Box>

          <Box className={classes.eachBox}>
            <Box className={classes.containSubTitle}>
              <h3 className={classes.subTitle}>Professional history</h3>
            </Box>
            <Box className={classes.border}>
              {values?.professionalHistory?.map((item) => {
                return (
                  <Box key={item._id} className={classes.containEducation}>
                    <Box className={classes.containEducationData}>
                      <h4 className={classes.h6}>
                        {isNaN(new Date(item?.startDate).getFullYear())
                          ? "YYYY"
                          : new Date(item?.startDate).getFullYear()}{" "}
                        -{" "}
                        {item?.isCurrentEmployee
                          ? "Present"
                          : isNaN(new Date(item?.startDate).getFullYear())
                          ? "YYYY"
                          : new Date(item?.endDate).getFullYear()}
                      </h4>
                      <h4 className={classes.h6}>
                        {item?.jobTitle ? item?.jobTitle : DUMMY_JOB_TITLE}{" "}
                      </h4>
                    </Box>
                    <Box className={classes.containEducationData}>
                      <h4 className={classes.educationH4}>
                        {item?.employeer ? item?.employeer : DUMMY_EMPLOYEER}
                      </h4>
                      <p className={classes.educationPara}>
                        {item.isBulletin
                          ? item.desc.split("\n").map((item, index) => {
                              return <li key={index}>{item}</li>;
                            })
                          : item?.desc
                          ? item?.desc
                          : DUMMY_EXPERIENCE_DESCRIPTION}
                      </p>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box className={classes.eachBox}>
            <Box className={classes.containSubTitle}>
              <h3 className={classes.subTitle}>Project history</h3>
            </Box>
            <Box className={classes.border}>
              {values?.projects?.map((item) => {
                return (
                  <Box key={item._id} className={classes.containEducation}>
                    <Box className={classes.containEducationData}>
                      <h4 className={classes.h6}>
                        {item?.startDate && item?.startDate >= 1
                          ? `${item?.startDate} Year -`
                          : null}{" "}
                        {item?.endDate && item?.endDate >= 1
                          ? `${item?.endDate} Months`
                          : null}
                      </h4>
                      <h4 className={classes.h6}>
                        {item?.projectName
                          ? item?.projectName
                          : DUMMY_PROJECT_NAME}{" "}
                      </h4>
                    </Box>
                    <Box className={classes.containEducationData}>
                      <h4 className={classes.educationH4}>
                        {item?.technologyUsed
                          ? item?.technologyUsed
                          : DUMMY_PROJECT_TECHNOLOGY}
                      </h4>
                      <p className={classes.educationPara}>
                        {
                          // console.log(item.isBulletin, "item")
                        }
                        {item.isBulletin
                          ? item.desc.split("\n").map((item, index) => {
                              // console.log("item", index, item);
                              if (item.trim())
                                return <li key={index}>{item}</li>;
                            })
                          : item?.desc
                          ? item?.desc
                          : DUMMY_PROJECT_DESCRIPTION}
                      </p>
                    </Box>
                    {item?.refLink ? (
                      <Box style={{ textAlign: "left" }}>
                        <p className={classes.refLinkText}>References: </p>
                        {item?.refLink
                          ? item.refLink?.map((eachRef) => {
                              return eachRef ? (
                                <div
                                  key={eachRef?.projectLink}
                                  style={{ display: "flex" }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {eachRef?.projectTitle}{" "}
                                  </p>
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.refLink}
                                    href={
                                      eachRef?.projectLink?.split("//")
                                        ?.length !== 2
                                        ? `https://${eachRef?.projectLink}`
                                        : eachRef?.projectLink
                                    }
                                  >
                                    {eachRef?.projectLink?.slice(0, 20)}
                                    {eachRef?.projectLink?.length >= 20
                                      ? "...."
                                      : null}
                                  </a>
                                </div>
                              ) : null;
                            })
                          : null}
                      </Box>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box className={classes.eachBox}>
            <Box className={classes.containSubTitle}>
              <h3 className={classes.subTitle}>Skills</h3>
            </Box>
            <Box style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <Box className={classes.border}>
                <Box className={classes.containEducation}>
                  <Box className={classes.containEducationData}>
                    <h4 className={classes.h6}>Professional Skills</h4>
                  </Box>
                  {values?.professionalSkills?.map((skill, index) => {
                    return (
                      <Box className={classes.containStarData} key={skill._id}>
                        <h6 className={classes.h6Light}>
                          {skill.skill ? skill.skill : DUMMY_PROFESSIONAL_SKILL}
                        </h6>
                        <Box className={classes.containStars}>
                          <img
                            className={classes.star}
                            src={
                              skill.level >= 1
                                ? ORANGE_STAR_ICON
                                : GREY_STAR_ICON
                            }
                            alt="star"
                          />
                          <img
                            className={classes.star}
                            src={
                              skill.level >= 2
                                ? ORANGE_STAR_ICON
                                : GREY_STAR_ICON
                            }
                            alt="star"
                          />
                          <img
                            className={classes.star}
                            src={
                              skill.level >= 3
                                ? ORANGE_STAR_ICON
                                : GREY_STAR_ICON
                            }
                            alt="star"
                          />
                          <img
                            className={classes.star}
                            src={
                              skill.level >= 4
                                ? ORANGE_STAR_ICON
                                : GREY_STAR_ICON
                            }
                            alt="star"
                          />
                          <img
                            className={classes.star}
                            src={
                              skill.level >= 5
                                ? ORANGE_STAR_ICON
                                : GREY_STAR_ICON
                            }
                            alt="star"
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box className={classes.border}>
                <Box className={classes.containEducation}>
                  <Box>
                    <Box className={classes.containEducationData}>
                      <h4 className={classes.h6}>Personal Skills</h4>
                    </Box>
                    {values?.personalSkills?.map((skill, index) => {
                      return (
                        <Box
                          className={classes.containStarData}
                          key={skill._id}
                        >
                          <h6 className={classes.h6Light}>
                            {skill.skill ? skill.skill : DUMMY_PERSONAL_SKILL}{" "}
                          </h6>
                          <Box className={classes.containStars}>
                            <img
                              className={classes.star}
                              src={
                                skill.level >= 1
                                  ? ORANGE_STAR_ICON
                                  : GREY_STAR_ICON
                              }
                              alt="star"
                            />
                            <img
                              className={classes.star}
                              src={
                                skill.level >= 2
                                  ? ORANGE_STAR_ICON
                                  : GREY_STAR_ICON
                              }
                              alt="star"
                            />
                            <img
                              className={classes.star}
                              src={
                                skill.level >= 3
                                  ? ORANGE_STAR_ICON
                                  : GREY_STAR_ICON
                              }
                              alt="star"
                            />
                            <img
                              className={classes.star}
                              src={
                                skill.level >= 4
                                  ? ORANGE_STAR_ICON
                                  : GREY_STAR_ICON
                              }
                              alt="star"
                            />
                            <img
                              className={classes.star}
                              src={
                                skill.level >= 5
                                  ? ORANGE_STAR_ICON
                                  : GREY_STAR_ICON
                              }
                              alt="star"
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.eachBox}>
            <Box className={classes.containSubTitle}>
              <h3 className={classes.subTitle}>Hobbies</h3>
            </Box>
            <Box className={classes.border}>
              <Box className={classes.containHobby}>
                {values?.hobbies?.map((item) => {
                  return (
                    <Box key={item} className={classes.eachHobbie}>
                      <p style={{ color: "#F47954", margin: "5px" }}>
                        {item ? item : DUMMY_HOBBY}
                      </p>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Fragment>
  );
};

export default RealTimeTemplate;

import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const CollapsableElement = ({ message, icon }) => {
  return (
    <div
      style={{
        border: "1px dashed rgba(244, 121, 84, 0.55)",
        borderLeft: "none",
        borderRight: "none",
        color: "#F47954",
        fontWeight: "bold",
        textAlign: "left",
        cursor: "pointer",
        marginBottom: "1rem",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <p>{message}</p>
      {icon === "open" ? (
        <KeyboardArrowDownIcon sx={{ alignSelf: "center" }} />
      ) : (
        <KeyboardArrowUpIcon sx={{ alignSelf: "center" }} />
      )}
    </div>
  );
};

export default CollapsableElement;

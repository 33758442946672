import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import PersonalInformationForm from "./PersonalInformationForm";
import EducationInfoForm from "./EducationInfoForm";
import ProfessionalInfoForm from "./ProfessionalInfoForm";
import ProjectInfoForm from "./ProjectInfoForm";
import SkillsInfoForm from "./SkillsInfoForm";
import HobbiesInfoForm from "./HobbiesInfoForm";
import { styles } from "../../styles/styles";
import { RESOURCIFI_CIRCULAR_LOGO } from "../../utils/constants/constants";
import BtnLoader from "../Loader/BtnLoader";
import AcademicAchievementForm from "./AcademicAchievementsForm";

function StepperForm(props) {
  const classes = styles();
  const { values, submitForm } = props.formikProps;
  const { activeStep, setActiveStep, forwardBtnName } = props;
  const { loading } = props;

  const steps = [
    {
      label: "Personal Information",
      component: (
        <PersonalInformationForm
          values={values}
          formikProps={props.formikProps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Education Information",
      component: (
        <EducationInfoForm
          values={values}
          formikProps={props.formikProps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Academic Achievement",
      component: (
        <AcademicAchievementForm
          values={values}
          formikProps={props.formikProps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },

    {
      label: "Professional History",
      component: (
        <ProfessionalInfoForm
          values={values}
          formikProps={props.formikProps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Projects",
      component: (
        <ProjectInfoForm
          values={values}
          formikProps={props.formikProps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Skills",
      component: (
        <SkillsInfoForm
          values={values}
          formikProps={props.formikProps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Hobbies",
      component: (
        <HobbiesInfoForm
          values={values}
          submitForm={submitForm}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          formikProps={props.formikProps}
        />
      ),
    },
  ];

  console.log(values);
  const handleNext = async () => {
    submitForm();
  };

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  console.log("props", props);
  return (
    <Box sx={{ padding: 0 }}>
      <div className={classes.containHead}>
        <div className={classes.continHeadInfo}>
          <h2
            style={{
              margin: 0,
              fontWeight: "bold",
              fontSize: "24px",
              fontFamily: "Poppins",
            }}
          >
            Tell us about yourself
          </h2>
          <p
            style={{
              margin: 0,
              textAlign: "left",
              fontWeight: "bold",
              color: "#7F8182",
            }}
          >
            With this info, we will be able to create your resume.
          </p>
        </div>
        <div className={classes.continHeadInfo}>
          <img
            src={RESOURCIFI_CIRCULAR_LOGO}
            className={classes.headLogo}
            alt="logo"
          />
        </div>
      </div>

      <Stepper activeStep={activeStep} orientation="vertical">
        <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <hr
              width="1"
              size="55"
              color="#F47954"
              style={{
                marginRight: "13px",
                marginBottom: 0,
                marginTop: 0,
                opacity: 0.5,
              }}
            />

            {steps.map((step, index) => (
              <div key={step.label}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <p
                    style={{
                      paddingRight: "1rem",
                      margin: 0,
                      width: "195px",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    {step.label}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className={
                        activeStep >= index
                          ? classes.selectedCircle
                          : classes.unselectedCircle
                      }
                    >
                      {index + 1}
                    </span>
                  </div>
                </div>
                <hr
                  width="1"
                  size="55"
                  color={activeStep >= index + 1 ? "#F47954" : "#7f8182"}
                  style={{
                    marginRight: "13px",
                    marginBottom: 0,
                    marginTop: 0,
                    opacity: 0.5,
                  }}
                />
              </div>
            ))}
          </div>
          <div>
            {steps[activeStep].component}
            <Box sx={{ mb: 2 }}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {loading ? (
                  <BtnLoader />
                ) : (
                  <button onClick={handleNext} className={classes.nextBtn}>
                    {activeStep === steps.length - 1
                      ? "Submit"
                      : forwardBtnName}
                  </button>
                )}
                <button
                  disabled={activeStep === 0}
                  className={classes.prevBtn}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1, cursor: "pointer" }}
                >
                  Back
                </button>
              </div>
            </Box>
          </div>
        </div>
      </Stepper>
    </Box>
  );
}

export default StepperForm;

import React, { Fragment } from 'react';
import { makeStyles } from "@mui/styles";
import { Box } from '@mui/system';
import { ARROW_IMAGE } from '../../utils/constants/constants';

const styles = makeStyles({
    footerContainer:{
        background:"#162642",
        padding:"1rem 0"
    },
    footerContent:{
        color:"white",
        fontSize:'18px',
        fontWeight:'bolder',
        textAlign:'center',
        marginBottom:0
    },
    btn:{
        background:"white",
        border:"none",
        padding:"0.5rem 2.5rem",
        color:"#162642",
        fontWeight:"bold",
        borderRadius:2,
        height:"40px"
    },
    containBtn:{
        display:"flex",
        justifyContent:"center"
    },
    containHireBtn:{
        display:"flex",
        flexDirection:'column',
        justifyContent:"flex-end"
    }
})

const Footer = () => {

    const classes = styles();
    return <Fragment>
        <Box className={classes.footerContainer} >
            <h2 className={classes.footerContent} >Do you have any project?</h2>
            <Box className={classes.containBtn} >
                <div className={classes.containHireBtn} >
                    <button className={classes.btn} >Hire Me</button>                
                </div>
                <img src={ ARROW_IMAGE } alt="" />
            </Box>
        </Box>
    </Fragment>
}

export default Footer;
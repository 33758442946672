import React, { useState, Fragment } from "react";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  LOGIN_PATH,
  UPDATE_PASSWORD,
} from "../../utils/constants/constants";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { checkLoggedIn } from "../../api/Auth";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import { TextField, Container, Typography } from "@mui/material";
import { styles } from "../../styles/styles";
import { Box } from "@mui/system";

const Profile = () => {
  const classes = styles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  React.useEffect(() => {
    const isLoggedIn = checkLoggedIn();
    if (!isLoggedIn) {
      navigate(LOGIN_PATH);
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    };
    axios
      .get(`${BASE_URL}/api/user/detail`, config)
      .then((response) => {
        if (response.data.statusCode === 200) {
          // console.log('user',response.data.data.user.teamId[0]?.teamName)
          setUserInfo(response.data.data.user);
          setLoading(false);
        }
      })
      .catch((error) => {
        CustomEventEmitter.emit(
          "alert_error",
          error?.response?.data?.message || "unknown or network error"
        );
        setLoading(false);
      });
  }, [navigate]);

  const handleChangePassword = (e) => {
    e.preventDefault();
    navigate(UPDATE_PASSWORD);
  };

  return (
    <Fragment>
      <Container
        component="main"
        maxWidth="sm"
        className={classes.updatePasswordContainer}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
          }}
          className={classes.loginContainer}
        >
          <Typography>
            <p className={classes.signInHead}>Account Profile</p>
          </Typography>
          {loading ? (
            <Loader />
          ) : (
            <Box
              component="form"
              onSubmit={handleChangePassword}
              noValidate
              className={classes.inputFieldContainer}
            >
              <TextField
                disabled={true}
                margin="normal"
                required
                className={classes.inputField}
                label="First Name"
                value={userInfo?.firstName || ""}
              />
              <TextField
                disabled={true}
                margin="normal"
                required
                className={classes.inputField}
                label="Last Name"
                name="oldPassword"
                value={userInfo?.lastName || ""}
              />

              <TextField
                disabled={true}
                margin="normal"
                required
                className={classes.inputField}
                label="Email"
                name="newPassword"
                value={userInfo?.email || ""}
              />

              <TextField
                disabled={true}
                margin="normal"
                required
                className={classes.inputField}
                label="Team"
                sx={{ mb: 2 }}
                value={
                  userInfo?.teamId.map((team) => {
                    return `${team.teamName}`;
                  }) || ""
                }
              />
              <button
                type="submit"
                variant="contained"
                className={classes.resetPasswordBtn}
              >
                Reset Password
              </button>
            </Box>
          )}
        </Box>
      </Container>
    </Fragment>
  );
};

export default Profile;

import React from "react";
import { useField } from "formik";
import { TextField } from "@mui/material";
import { styles } from "../../styles/styles";

export const FormikTextField = ({ placeholder, ...props }) => {
  const classes = styles();
  const [field, meta] = useField(props);
  const errorText = meta.error ? meta.error : "";

  return (
    <TextField
      placeholder={placeholder}
      {...field}
      {...props}
      className={classes.textField}
      helperText={errorText}
      error={!!errorText}
    />
  );
};

import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { get, isObject } from "lodash";

export const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const {
    form: { setTouched, setFieldValue, touched },
    label,
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  const handleOnblur = () => {
    setTouched({ ...touched, [name]: true });
  };

  const handleOnChange = (event, value) => {
    console.log(typeof value);
    setFieldValue(name, value);
    if (props && props.onChange) {
      props.onChange(event, value);
    }
  };

  const handleOptionLabel = (option) => {
    // console.log({ option });
    if (isObject(option)) {
      return option.value || "";
    } else {
      return option;
    }
  };

  return (
    <Autocomplete
      {...props}
      {...field}
      options={props?.options || []}
      getOptionLabel={
        props?.getOptionLabel ? props?.getOptionLabel : handleOptionLabel
      }
      onChange={(event, value) => handleOnChange(event, value)}
      onBlur={handleOnblur}
      inputValue={props?.inputValue && props?.inputValue}
      size="medium"
      renderInput={(props) => (
        <TextField
          placeholder={label}
          label={label}
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
          size="medium"
          style={{
            width: "100%",
          }}
        />
      )}
    />
  );
};

export const fieldToTextField = ({
  disabled,
  field,
  form: { isSubmitting, errors },
  ...props
}) => {
  const fieldError = get(errors, field.name);
  const showError = !!fieldError;

  return {
    ...props,
    ...field,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: disabled || isSubmitting,
    variant: props.variant,
  };
};

import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import {
  BASE_URL,
  DASHBOARD_PATH,
  ROOT_PATH,
} from "../../utils/constants/constants";
import CommonTable from "../../components/Table/Table";
import Loader from "../../components/Loader/Loader";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import { styles } from "../../styles/styles";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isResumeEligible } from "../../utils/helper/checkDesignation";

const Employees = () => {
  const navigate = useNavigate();
  const classes = styles();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(false);
  const [isSalesPerson, setIsSalesPerson] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const validate = isResumeEligible(user.userType);
    setIsSalesPerson(validate);
    if (!validate || user?.userType === "Team Lead") {
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      axios
        .get(`${BASE_URL}/api/user/list/in/my-team`, config)
        .then((response) => {
          // console.log("response", response?.data?.data?.userList);
          setData(response?.data?.data?.userList || []);
        })
        .catch((error) => {
          // console.log("error.response", error.response);
          CustomEventEmitter.emit(
            "alert_error",
            error?.response?.data?.message || "unknown or network error"
          );
          if (error?.response?.data?.statusCode === 401) {
            navigate("/auth/login");
            localStorage.clear();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate(ROOT_PATH);
    }
  }, [flag, navigate]);

  const columns = [
    { id: "sno", label: "S No." },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    {
      id: "userTypeId",
      label: "Designation",
      align: "left",
    },
    { id: "resumeStatus", label: "Resume Status" },

    {
      id: "employeeStatusId",
      label: "Employee Status",
      align: "left",
    },
  ];
  return (
    <Fragment>
      <Box sx={{ background: "white" }}>
        {isSalesPerson && (
          <Box className={classes.containHeadEmployee}>
            <Box className={classes.containHeaders}>
              <h5
                className={classes.headers}
                onClick={() => navigate(DASHBOARD_PATH)}
              >
                My Dashboard
              </h5>
              <h5 className={classes.headers}>My Team</h5>
            </Box>
            <Box className={classes.flex}></Box>
          </Box>
        )}

        {loading ? (
          <Loader />
        ) : data.length ? (
          <CommonTable
            columns={columns}
            rows={data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setRows={setData}
            setFlag={setFlag}
            flag={flag}
          />
        ) : null}
      </Box>
    </Fragment>
  );
};

export default Employees;

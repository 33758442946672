import { makeStyles } from "@mui/styles";

export const styles = makeStyles({
  resetPasswordBtn: {
    padding: "15px !important",
    backgroundColor: "#F47954 !important",
    color: "white",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    fontWeight: "500",
    width: "50%",
    fontSize: 16,
  },

  containHeaders: {
    display: "flex",
    margin: "0 0.2rem",
  },
  containHeadEmployee: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem",
    borderBottom: "1px solid rgba(127, 129, 130, 0.3)",
  },
  headers: {
    marginRight: "1rem",
    fontSize: "14px",
    cursor: "pointer",
  },
  icon: {
    width: 17,
    height: 17,
    marginRight: "1rem",
    cursor: "pointer",
  },
  headLogo: {
    width: "120px",
    height: "120px",
    objectFit: "contain",
  },
  containHead: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 ",
  },
  continHeadInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  collapse: {
    background: "white",
    border: "1px dashed rgba(244, 121, 84, 0.55)",
    borderLeft: "none",
    borderRight: "none",
    color: "#F47954",
    fontWeight: "bold",
    textAlign: "left",
    flex: 1,
    cursor: "pointer",
    marginBottom: "1rem",
  },

  nextBtn: {
    border: "none",
    background: "#F47954",
    padding: "0.5rem 2.7rem",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: 5,
    cursor: "pointer",
  },
  prevBtn:{
    border: "none",
    background: "gray",
    padding: "0.5rem 2.7rem",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: 5,
    cursor: "pointer",
    maxHeight:40

  },
  // dashBoardLayout

  usercontain: {
    display: "flex",
    cursor: "pointer",
  },
  containLoggedInUser: {
    display: "flex",
    padding: "10% 0.5rem",
    justifyContent: "center",
  },
  dashboardUser: {
    height: "70px",
    borderRadius: "50%",
  },
  loggedInUserName: {
    margin: 0,
    fontSize: "20px",
  },
  loggedInUserEmail: {
    margin: 0,
    color: "#7F8182",
    fontSize: "15px",
  },
  loggedInUserDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  activeAction: {
    fontWeight: "bold",
    color: "#F47954",
  },
  logoutbtn: {
    background: "rgba(244, 121, 84, 0.12)",
    color: "#F47954",
    border: "none",
    fontWeight: "bold",
    padding: "7px 15px",
    fontSize: "16px",
    marginLeft: "1rem",
    cursor: "pointer",
  },
  // dashBoardLayout

  //Stepper Form Styles

  containImageUpload: {
    display: "flex",
  },
  userImage: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
  },

  selectedCircle: {
    display: "flex",
    flexDirection: "column",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    background: "#F47954",
    color: "white",
    lineHeight: 1.8,
    border: "1px solid #F47954",
  },
  unselectedCircle: {
    display: "flex",
    flexDirection: "column",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    border: "1px solid grey",
    margin: 0,
    lineHeight: 1.8,
  },

  //Stepper Form Styles

  //Employee screen

  employeeTitle: {
    color: "#7F8182",
    fontWeight: "bold",
    fontSize: "22px",
    margin: "0 0 1rem 0",
  },

  tableColumns: {
    color: "#7F8182 !important",
    fontWeight: "bold !important",
    opacity: "0.5",
    textAlign:"center"
  },
  tableRows: {
    color: "#7F8182 !important",
    fontWeight: "bold !important",
  },
  //Employee screen

  logo: {
    maxWidth: "150px",
    margin: "auto",
    cursor: "pointer",
  },
  loader: {
    color: "#F47954 !important",
  },
  inputField: {
    border: "1px solid #7F8182",
    borderRadius: "7px",
    width: "80%",
    display: "block",
    fontWeight: "200",
  },
  formInputField: {
    border: "1px solid #7F8182",
    borderRadius: "7px",
    display: "block",
    fontWeight: "200",
  },
  inputFieldContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    alignItems:"center"
  },
  root1: {
    height: "100vh",
    display: "flex !important",
    flexDirection: "column",
    justifyContent: "center",
  },
  updatePasswordContainer: {
    display: "flex",
  },
  loginContainer1: {
    boxShadow: "0 8px 24px 0 rgb(23 50 101 / 30%)",
    borderRadius: 2,
    background: "white",
    padding: " 40px",
  },


  loginContainer: {
    borderRadius: 2,
    background: "white",
    width: "100%",
    padding: "1rem 2rem",
    boxShadow: "none !important",
  },
  stepperContainer: {
    borderRadius: 2,
    background: "white",
    width: "100%",
    padding: "1rem 0rem",
    boxShadow: "none !important",
  },

  resourcifiLogo: {
    width: "230px",
  },
  signInHead: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "20px !important",
    width: "100%",
    paddingBottom: "20px",
    color: "black",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },
  forgotPasswordContainer: {
    display: "flex",
    justifyContent: "end",
  },
  forgotPasswordBtn: {
    color: "#F47954",
    fontWeight: "600",
    fontSize: "18px",
  },
  SignInBtn: {
    padding: "15px !important",
    backgroundColor: "#F47954 !important",
    color: "white",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    fontWeight: "500",
    width: "100%",
    fontSize: 16,
  },
  actionBtnWitMargin: {
    padding: "15px !important",
    backgroundColor: "#F47954 !important",
    color: "white",
    margin: "1rem 0 !important",
  },
});

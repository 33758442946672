import React, { Fragment, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { styles } from "../../styles/styles";
import Box from "@mui/material/Box";
import { FormikTextField } from "../FormControlsWithFormik";
import { DELETE_ICON } from "../../utils/constants/constants";

const HobbiesInfoForm = (props) => {
  const { values } = props;
  const classes = styles();
  const [skills, setSkills] = useState([""]);

  const [flag, setFlag] = useState(true);

  // console.log("valuesss", values.hobbies);

  const handleAddMore = (e) => {
    e.preventDefault();
    // const lastValue = skills[skills.length - 1];

    if (
      values.hobbies[values.hobbies.length - 1] &&
      skills.length === values.hobbies.length
    ) {
      setSkills([...skills, ""]);
    }
  };

  useEffect(() => {
    if (values.hobbies.length) {
      setSkills(values.hobbies);
    }
  }, [flag]);

  const handleDelete = (index) => {
    setSkills([]);

    values.hobbies.splice(index, 1);
    props.formikProps.setFieldValue("hobbies", values.hobbies);
    flag ? setFlag(false) : setFlag(true);
  };

  return (
    <Fragment>
      <Container component="main" className={classes.updatePasswordContainer}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
          }}
          className={classes.stepperContainer}
        >
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h4
                style={{
                  textAlign: "left",
                  margin: 0,
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Hobbies
              </h4>
              <p>Add the things you like</p>
            </div>
          </Box>
          {skills &&
            skills.map((skill, index) => {
              return (
                <Box
                  key={skill}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <div style={{ width: "100%", marginRight: "1rem" }}>
                    <h2
                      style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}
                    >
                      Hobby
                    </h2>
                    <FormikTextField
                      fullWidth={true}
                      margin="normal"
                      required
                      label="Add Hobby"
                      variant="outlined"
                      name={`hobbies[${index}]`}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 25,
                      marginLeft: 10,
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {index >= 1 && (
                        <div>
                          <img
                            onClick={() => handleDelete(index)}
                            src={DELETE_ICON}
                            style={{ width: "20px", height: "20px" }}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              );
            })}

          <Box
            sx={{
              alignItems: "right",
              textAlign: "right",
              margin: "1rem 2rem",
            }}
          >
            <button className={classes.logoutbtn} onClick={handleAddMore}>
              Add More
            </button>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

export default HobbiesInfoForm;

import React, { Fragment, useState } from "react";
import Container from "@mui/material/Container";
import { styles } from "../../styles/styles";
import Box from "@mui/material/Box";
import { FormikTextField, FormikAutocomplete } from "../FormControlsWithFormik";
import { DELETE_ICON, OPEN_ICON, YEARS } from "../../utils/constants/constants";
import _ from "lodash";
import { Field } from "formik";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const AcademicAchievementForm = (props) => {
  const classes = styles();
  const { values } = props;
  const [flag, setFlag] = useState(true);
  const [openIcon, setOpenIcon] = useState(-1);
  const [deleteBtn, setDeleteBtn] = useState(false);

  // handle index and previous saved data
  const listLength =
    values.achievement?.length !== 0 ? values.achievement?.length - 1 : 0;

  const previousSavedData = listLength === 0 ? [] : values.achievement;

  const [listIndex, setListIndex] = useState(listLength);
  const [showAchievementsList, setShowAchievementsList] =
    useState(previousSavedData);

  const checkValidation = () => {
    return !(
      _.isEmpty(values.achievement[listIndex].achievementName) ||
      _.isEmpty(values.achievement[listIndex].completionYear) ||
      _.isEmpty(values.achievement[listIndex].desc)
    )
      ? true
      : false;
  };

  const checkEmptyFields = () => {
    let empty = false;
    for (const ach of values?.achievement) {
      if (
        _.isEmpty(ach.achievementName) ||
        _.isEmpty(ach.completionYear) ||
        _.isEmpty(ach.desc)
      ) {
        empty = true;
        break;
      }
    }
    setListIndex(values.achievement?.length - 1);
    return empty;
  };

  const handleAchievement = (e) => {
    // e.preventDefault();
    // if (checkEmptyFields()) {

    // }

    if (!checkEmptyFields()) {
      setShowAchievementsList(values?.achievement);
      setDeleteBtn(true);
      props.formikProps.setFieldValue("achievement", [
        ...values?.achievement,
        {
          achievementName: "",
          completionYear: "",
          desc: "",
        },
      ]);
      setListIndex(values.achievement?.length);
      setOpenIcon(-1);
    }
  };

  const handleShow = (data, index) => {
    if (checkValidation()) {
      setOpenIcon(index);
      setShowAchievementsList(values?.achievement);
      setListIndex(index);
    } else {
      values.achievement.pop();
      setOpenIcon(index);
      setShowAchievementsList(values?.achievement);
      setListIndex(index);
    }
  };

  const handleDelete = async (data, index) => {
    showAchievementsList.splice(index, 1);
    values?.achievement?.splice(index, 1);
    flag ? setFlag(false) : setFlag(true);
    setListIndex(listIndex - 1);
    if (values?.achievement?.length === 0) {
      handleAchievement();
    }
  };
  const handleOpenDelete = () => {
    setDeleteBtn(false);

    values?.achievement?.pop();
    setOpenIcon(values?.achievement?.length - 1);
    setListIndex(values?.achievement?.length - 1);
  };
  // console.log("values.achievement", values.achievement);
  return (
    <Fragment>
      <Container component="main" className={classes.updatePasswordContainer}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
            // minHeight: "85vh",
          }}
          className={classes.loginContainer}
        >
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h4
                style={{
                  textAlign: "left",
                  margin: 0,
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Academic Achievement
              </h4>
              {/* <p>Mention the recent degree first</p> */}
            </div>
            <div
              style={{ alignSelf: "center", marginLeft: "200px" }}
              onClick={handleAchievement}
            >
              <button className={classes.logoutbtn}>Add</button>
            </div>
            {values.achievement[0]?.achievementName?.length == 0 ||
            !values.achievement[0] ? (
              <div
                style={{ alignSelf: "center" }}
                onClick={() => {
                  props.formikProps.setFieldValue("haveAchievement", false);
                  props.formikProps.setFieldValue("achievement", [
                    {
                      achievementName: "",
                      completionYear: "",
                      desc: "",
                    },
                  ]);

                  props.setActiveStep(3);
                }}
              >
                <button className={classes.logoutbtn}>SKIP</button>
              </div>
            ) : (
              <></>
            )}
          </Box>

          {showAchievementsList.length > 0 &&
            showAchievementsList?.map((achList, index) => {
              return (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.6rem 0",
                    borderTop: "1px solid rgba(127, 129, 130, 0.3)",
                    borderBottom: "1px solid rgba(127, 129, 130, 0.3)",
                  }}
                >
                  <div>
                    <p style={{ margin: 0, textAlign: "left" }}>
                      {`${achList?.achievementName} - ${new Date(
                        achList.completionYear
                      ).getFullYear()}, `}
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <img
                      alt=""
                      src={OPEN_ICON}
                      style={
                        openIcon === -1 || openIcon !== index
                          ? { width: 20, height: 20 }
                          : {
                              width: 20,
                              height: 20,
                              transform: "rotateX(180deg)",
                            }
                      }
                      onClick={() => handleShow(achList, index)}
                    />
                    <img
                      alt=""
                      src={DELETE_ICON}
                      style={{ width: 20, height: 20, marginLeft: 10 }}
                      onClick={() => handleDelete(achList, index)}
                    />
                  </div>
                </Box>
              );
            })}
          {values?.achievement?.length > 1 && deleteBtn ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1rem",
              }}
            >
              <img
                alt=""
                src={DELETE_ICON}
                style={{ width: 20, height: 20, marginLeft: 10 }}
                onClick={handleOpenDelete}
              />
            </Box>
          ) : null}

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <div style={{ width: "100%", marginRight: "1rem" }}>
              <h2 style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}>
                Achievement Title
              </h2>
              <FormikTextField
                fullWidth
                margin="normal"
                required
                label="Achievement Title"
                variant="outlined"
                name={`achievement[${listIndex}].achievementName`}
                onBlur={() => {
                  props.formikProps.setFieldValue(`haveAchievement`, true);
                }}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <div style={{ width: "100%", marginRight: "1rem" }}>
              <h2
                style={{
                  color: "#7F8182",
                  fontSize: "18px",
                  margin: 0,
                  marginBottom: "20px",
                }}
              >
                Year
              </h2>

              <Field
                name={`achievement[${listIndex}].completionYear`}
                fullWidth
                label={"Year"}
                component={FormikAutocomplete}
                options={YEARS}
                textFieldProps={{
                  variant: "outlined",
                }}
                required
              />
            </div>
          </Box>

          <div style={{ width: "100%" }}>
            <h2 style={{ color: "#7F8182", fontSize: "18px" }}>Description</h2>
            <Box sx={{ display: "flex", width: "100%" }}>
              <FormikTextField
                fullWidth
                aria-label="empty textarea"
                placeholder="Achievement Description"
                rows={7}
                required
                multiline
                name={`achievement[${listIndex}].desc`}
              />
            </Box>
          </div>
        </Box>
      </Container>
    </Fragment>
  );
};

export default AcademicAchievementForm;

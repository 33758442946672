import React, { Fragment, useState } from "react";
import Container from "@mui/material/Container";
import { styles } from "../../styles/styles";
import Box from "@mui/material/Box";
import { FormikAutocomplete, FormikTextField } from "../FormControlsWithFormik";
import {
  DELETE_ICON,
  OPEN_ICON,
  PROJECT_YEARS,
  REFERENCE_LIST,
} from "../../utils/constants/constants";
import _ from "lodash";
import { Field } from "formik";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// date formatter for old and previous data

const ProjectInfoForm = (props) => {
  const classes = styles();
  const { values } = props;
  const [flag, setFlag] = useState(true);
  const [openIcon, setOpenIcon] = useState(-1);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [proj_isChecked, setProj_IsChecked] = useState(false);

  // handle index and previous saved data
  const listLength =
    values.projects?.length !== 0 ? values.projects?.length - 1 : 0;
  const previousSavedData = listLength === 0 ? [] : values.projects;

  const [listIndex, setListIndex] = useState(listLength);
  const [showProjectList, setShowProjectList] = useState(previousSavedData);
  // const refLength = values.projects[listIndex]?.refLink?.length !== 0 ? values.projects[listIndex]?.refLink?.length - 1 : 0;
  const references = [];
  const refLength = references.length !== 0 ? references.length - 1 : 0;
  const [refIndex, setRefIndex] = useState(refLength);

  // // console.log('checkkkkkkk', values.projects[listIndex].refLink )
  const checkValidation = () => {
    return !(
      _.isEmpty(values.projects[listIndex].projectName) ||
      _.isEmpty(values.projects[listIndex].technologyUsed) ||
      _.isEmpty(values.projects[listIndex].desc)
    )
      ? true
      : false;
  };

  const checkEmptyFields = () => {
    let empty = false;
    for (const edu of values.projects) {
      if (
        _.isEmpty(edu.projectName) ||
        _.isEmpty(edu.technologyUsed) ||
        _.isEmpty(edu.desc) ||
        _.isEmpty(edu.startDate + "") ||
        _.isEmpty(edu.endDate + "")
      ) {
        empty = true;
        break;
      }
    }

    setListIndex(values.projects?.length - 1);
    return empty;
  };

  const handleAddProjectInfo = (e) => {
    e.preventDefault();
    // console.log("kkkkkk", values.projects);
    if (!checkEmptyFields()) {
      setShowProjectList(values.projects);
      setDeleteBtn(true);

      props.formikProps.setFieldValue("projects", [
        ...values.projects,
        {
          projectName: "",
          technologyUsed: "",
          startDate: "",
          endDate: "",
          desc: "",
          refLink: "",
        },
      ]);
      setListIndex(values.projects?.length);
      setOpenIcon(-1);
    }
  };

  const handleShow = (data, index) => {
    console.log("data", data);
    if (checkValidation()) {
      setOpenIcon(index);
      console.log("values.projects", values.projects);
      setShowProjectList(values.projects);
      setListIndex(index);
    } else {
      // values.projects.pop();
      setOpenIcon(index);
      setShowProjectList(values.projects);
      setListIndex(index);
    }
  };

  const handleOpenDelete = () => {
    setDeleteBtn(false);

    values?.projects?.pop();
    setOpenIcon(values?.projects?.length - 1);
    setListIndex(values?.projects?.length - 1);
  };

  const handleDelete = (data, index) => {
    showProjectList.splice(index, 1);
    values.projects.splice(index, 1);
    flag ? setFlag(false) : setFlag(true);
    setListIndex(listIndex - 1);
    if (values.projects?.length === 0) {
      props.formikProps.setFieldValue("projects", [
        {
          projectName: "",
          technologyUsed: "",
          startDate: "",
          endDate: "",
          desc: "",
        },
      ]);
    }
  };

  const checkEmptyLinks = () => {
    let empty = false;
    for (const edu of values.projects[listIndex].refLink) {
      // // console.log("refLink============", values.projects[listIndex]?.refLink);
      if (edu) {
        if (_.isEmpty(edu?.projectTitle) || _.isEmpty(edu?.projectLink)) {
          empty = true;
          break;
        }
      }
    }
    setRefIndex(values.projects[listIndex]?.refLink.length - 1);
    return empty;
  };

  const handleAddMoreLinks = (e) => {
    e.preventDefault();

    if (!checkEmptyLinks()) {
      const lastValue =
        values?.projects[listIndex]?.refLink[
        values?.projects[listIndex]?.refLink?.length - 1
        ];
      // console.log("last value", lastValue);
      props.formikProps.setFieldValue(`projects[${listIndex}].refLink`, [
        ...values.projects[listIndex].refLink,
        {
          projectLink: "",
          projectTitle: "",
        },
      ]);
      setRefIndex(refIndex + 1);
    }
  };

  const handleDeleteLink = (data, index) => {
    const tempValues = values.projects[listIndex]?.refLink?.splice(index, 1);
    // console.log("temp", tempValues);
    props.formikProps.setFieldValue(
      `projects[${listIndex}].refLink`,
      values.projects[listIndex].refLink
    );
    setRefIndex(refIndex - 1);
  };
  // console.log("here---", values?.projects);
  const warning =
    values.projects.filter((item) => {
      return !item.projectName || !item.technologyUsed || !item.desc;
    }).length !== 0;
  console.log("warning", warning);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(showProjectList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setShowProjectList(items);
    props.formikProps.setFieldValue("projects", items);
    console.log(result);
  }

  return (
    <Fragment>
      <Container component="main" className={classes.updatePasswordContainer}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
            // minHeight: "85vh",
          }}
          className={classes.stepperContainer}
        >
          {warning ? (
            <div style={{ color: "red", fontWeight: "bold" }}>
              Please fill all the required fields in all Projects .
            </div>
          ) : (
            <></>
          )}

          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h4
                style={{
                  textAlign: "left",
                  margin: 0,
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Projects
              </h4>
              <p>Mention the most recent project first</p>
            </div>
            <div style={{ alignSelf: "center" }} onClick={handleAddProjectInfo}>
              <button className={classes.logoutbtn}>Add</button>
            </div>
          </Box>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="showProjectList">
              {(provided) => (
                <div className="showProjectList" {...provided.droppableProps} ref={provided.innerRef}>
                  {showProjectList.map((proList, index) => {
                    return (
                      <Draggable key={proList?._id} draggableId={proList?._id} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0.6rem 0",
                                borderTop: "1px solid rgba(127, 129, 130, 0.3)",
                                borderBottom: "1px solid rgba(127, 129, 130, 0.3)",
                              }}
                            >
                              <div>
                                <p style={{ margin: 0 }}>
                                  {`${proList?.projectName}, ${proList?.startDate >= 1
                                    ? proList?.startDate + " Year -"
                                    : ""
                                    }  ${proList?.endDate >= 1 ? proList?.endDate + " Months" : ""
                                    }`}
                                </p>
                              </div>
                              <div>
                                <img
                                  alt=""
                                  src={OPEN_ICON}
                                  style={
                                    openIcon === -1 || openIcon !== index
                                      ? { width: 20, height: 20,cursor:"pointer" }
                                      : {
                                        width: 20,
                                        height: 20,
                                        transform: "rotateX(180deg)",
                                        cursor:"pointer"
                                      }
                                  }
                                  onClick={() => handleShow(proList, index)}
                                />
                                <img
                                  alt=""
                                  src={DELETE_ICON}
                                  style={{ width: 20, height: 20, marginLeft: 10,cursor:"pointer" }}
                                  onClick={() => handleDelete(proList, index)}
                                />
                              </div>
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>


          {values?.projects?.length > 1 && deleteBtn ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1rem",
              }}
            >
              <img
                alt=""
                src={DELETE_ICON}
                style={{ width: 20, height: 20, marginLeft: 10 }}
                onClick={handleOpenDelete}
              />
            </Box>
          ) : null}

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <div style={{ width: "100%", marginRight: "1rem" }}>
              <h2 style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}>
                Project Name*
              </h2>
              <FormikTextField
                fullWidth
                margin="normal"
                required
                className={classes.inputField}
                label="Project Name"
                variant="outlined"
                name={`projects[${listIndex}].projectName`}
              />
            </div>
            <div style={{ width: "100%" }}>
              <h2 style={{ color: "#7F8182", fontSize: "18px", margin: 0 }}>
                Technology Used*
              </h2>
              <FormikTextField
                margin="normal"
                required
                className={classes.inputField}
                label="Eg. Ruby Rails"
                fullWidth
                name={`projects[${listIndex}].technologyUsed`}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <div style={{ width: "100%", marginRight: "1rem" }}>
              <h2
                style={{
                  color: "#7F8182",
                  fontSize: "18px",
                  margin: 0,
                  marginBottom: "20px",
                }}
              >
                Years
              </h2>
              {/* <FormikTextField
                fullWidth
                margin="normal"
                required
                className={classes.inputField}
                variant="outlined"
                
                name={`projects[${listIndex}].startDate`}
                type="date"
              /> */}
              <Field
                name={`projects[${listIndex}].startDate`}
                fullWidth
                label={"years"}
                component={FormikAutocomplete}
                options={PROJECT_YEARS}
                textFieldProps={{
                  variant: "outlined",
                }}
                required
              />
            </div>
            <div style={{ width: "100%" }}>
              <h2
                style={{
                  color: "#7F8182",
                  fontSize: "18px",
                  margin: 0,
                  marginBottom: "20px",
                }}
              >
                Months
              </h2>
              {/* <FormikTextField
                margin="normal"
                required
                className={classes.inputField}
                fullWidth
                
                name={`projects[${listIndex}].endDate`}
                type="date"
              /> */}
              <Field
                name={`projects[${listIndex}].endDate`}
                fullWidth
                label={"Months"}
                component={FormikAutocomplete}
                options={PROJECT_YEARS}
                textFieldProps={{
                  variant: "outlined",
                }}
                required
              />
            </div>
          </Box>

          <div style={{ width: "100%" }}>
            <h2 style={{ color: "#7F8182", fontSize: "18px" }}>Description</h2>
            <FormGroup>
              <FormControlLabel
                onChange={(e) => {
                  setProj_IsChecked(!proj_isChecked);
                  props.formikProps.setFieldValue(
                    `projects[${listIndex}].isBulletin`,
                    e.target.checked
                  );
                }}
                control={
                  <Checkbox checked={values?.projects[listIndex]?.isBulletin} />
                }
                label="change to Bullets"
                value={values?.projects[listIndex]?.isBulletin}
              />
            </FormGroup>
            <Box sx={{ display: "flex", width: "100%" }}>
              <FormikTextField
                fullWidth
                placeholder="Enter your Project Details"
                className={classes.inputField}
                minRows={7}
                name={`projects[${listIndex}].desc`}
                rows={6}
                multiline
              />
            </Box>
          </div>
          <div style={{ width: "100%" }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <h2 style={{ color: "#7F8182", fontSize: "18px" }}>
                Reference Link
              </h2>
              <div style={{ alignSelf: "center" }} onClick={handleAddMoreLinks}>
                <button className={classes.logoutbtn}>Add</button>
              </div>
            </Box>
            {values?.projects[listIndex]?.refLink?.length
              ? values?.projects[listIndex]?.refLink?.map((eachRef, index) => {
                return eachRef ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold", marginRight: 3 }}>
                        {eachRef?.projectTitle}
                      </p>
                      <p>{eachRef?.projectLink}</p>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                      {eachRef?.projectLink && eachRef?.projectTitle && (
                        <img
                          alt=""
                          src={DELETE_ICON}
                          style={{ width: 20, height: 20, marginLeft: 10 }}
                          onClick={() => handleDeleteLink(eachRef, index)}
                        />
                      )}
                    </div>
                  </Box>
                ) : null;
              })
              : null}

            <Box sx={{ display: "flex", width: "100%", mt: 2 }}>
              <Field
                sx={{ marginRight: 2 }}
                name={`projects[${listIndex}].refLink[${refIndex}].projectTitle`}
                fullWidth
                label={"Project type"}
                component={FormikAutocomplete}
                options={REFERENCE_LIST}
                textFieldProps={{
                  variant: "outlined",
                }}
                required
              />

              <FormikTextField
                fullWidth
                placeholder="Enter Refernce Link"
                className={classes.inputField}
                name={`projects[${listIndex}].refLink[${refIndex}].projectLink`}
              />
            </Box>
          </div>
        </Box>
        {/* <pre>{JSON.stringify(values.projects, null, 2)}</pre> */}
      </Container>
    </Fragment>
  );
};

export default ProjectInfoForm;

import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  BASE_URL,
  LOGIN_PATH,
  RESUME_COMPLETE_PATH,
} from "../../utils/constants/constants";
import { checkLoggedIn } from "../../api/Auth";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StepperForm from "../../components/StepperForm/StepperForm";
import { Form, Formik } from "formik";
import {
  validationSchemaStep0,
  validationSchemaStep1,
  validationSchemaStep2,
  validationSchemaStep3,
  validationSchemaStep4,
  validationSchemaStep5,
} from "../../utils/validations";
import { createResume, createResumeTeamLead, fileUpload } from "../../api/api";
import axios from "axios";
import RealTimeTemplate from "../../components/RealTimeTemplate/RealTimeTemplate";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import { useParams } from "react-router-dom";
// import { useHistory } from "react-router";
import { canEditResume } from "../../utils/helper/checkDesignation";

const styles = makeStyles({
  LeftContainer: {
    background: "white",
    heightL: "100vh ",
  },
  rightContainer: {
    background: "#EBECEE",
    heightL: "100vh ",
  },
  logo: {
    width: "100px",
    height: "100px",
  },
  containHead: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    padding: "0 0 0 1rem",
  },
  continHeadInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  //
});

const UserDashboard = () => {
  // const history = useHistory();
  const classes = styles();
  const navigate = useNavigate();
  const { id } = useParams();
  const path = window.location.href.split("=");

  const resumeId = path[path.length - 1];

  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [forwardBtnName, setForwardBtnName] = useState("Next");
  const [data, setData] = useState({
    skillId: [],
    file: null,
    personalInfo: {
      profileImage: "",
      fullName: "",
      jobTitle: "",
      aboutMe: "",
    },
    educationInfo: [
      {
        college: "",
        degree: "",
        startDate: "",
        endDate: "",
        desc: "",
        isBulletin: "",
      },
    ],

    haveAchievement: true,
    achievement: [
      {
        achievementName: "",
        completionYear: "",
        desc: "",
      },
    ],
    professionalHistory: [
      {
        jobTitle: "",
        employeer: "",
        startDate: "",
        startMonth: "",
        endMonth: "",
        endDate: "",
        isCurrentEmployee: false,
        desc: "",
        isBulletin: "",
      },
    ],
    projects: [
      {
        projectName: "",
        technologyUsed: "",
        startDate: "",
        endDate: "",
        desc: "",
        isBulletin: "",
        refLink: [
          {
            projectTitle: "",
            projectName: "",
          },
        ],
      },
    ],
    professionalSkills: [
      {
        skill: "",
        level: "",
      },
    ],
    personalSkills: [
      {
        skill: "",
        level: "",
      },
    ],
    hobbies: [],
    isResumeComplete: false,
    tempHobbies: "",
  });

  useEffect(() => {
    const isLoggedIn = checkLoggedIn();
    if (!isLoggedIn) {
      navigate(LOGIN_PATH);
    }
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    const config = {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    };
    const canEdit = canEditResume(user?.userType);
    // console.log("edittttttttt", canEdit);
    if (resumeId) {
      axios
        .get(`${BASE_URL}/api/resume-detail/user/${resumeId}`, config)
        .then((response) => {
          if (response.data?.data.resumeDetail) {
            const savedHobbies =
              (response.data?.data?.resumeDetail.hobbies.length &&
                response.data?.data?.resumeDetail.hobbies.toString()) ||
              "";
            response.data.data.resumeDetail.tempHobbies = savedHobbies;
            setData(response.data?.data.resumeDetail);
          }
        })
        .catch((err) => {
          // console.log("err", err);
          CustomEventEmitter.emit(
            "alert_error",
            err?.response?.data?.message || "something went wrong"
          );
          if (err?.response?.data?.statusCode === 401) {
            navigate("/auth/login");
            localStorage.clear();
          }
        });
    }
    // if (canEdit && id?.length) {
    //   axios
    //     .get(`${BASE_URL}/api/resume-detail/user/admin?userId=${id}`, config)
    //     .then((response) => {
    //       if (response.data?.data.resumeDetail) {
    //         const savedHobbies =
    //           (response.data?.data?.resumeDetail.hobbies.length &&
    //             response.data?.data?.resumeDetail.hobbies.toString()) ||
    //           "";
    //         response.data.data.resumeDetail.tempHobbies = savedHobbies;
    //         setData(response.data?.data.resumeDetail);
    //       }
    //     })
    //     .catch((err) => {
    //       // console.log("err", err);
    //       CustomEventEmitter.emit(
    //         "alert_error",
    //         err?.response?.data?.message || "something went wrong"
    //       );
    //       if (err?.response?.data?.statusCode === 401) {
    //         navigate("/auth/login");
    //         localStorage.clear();
    //       }
    //     });
    // } else {
    //   axios
    //     .get(`${BASE_URL}/api/resume-detail/user/${id}`, config)
    //     .then((response) => {
    //       // console.log(response);
    //       if (response.data?.data.resumeDetail) {
    //         const savedHobbies =
    //           (response.data?.data?.resumeDetail.hobbies.length &&
    //             response.data?.data?.resumeDetail.hobbies.toString()) ||
    //           "";
    //         response.data.data.resumeDetail.tempHobbies = savedHobbies;
    //         setData(response.data?.data.resumeDetail);
    //         setForwardBtnName("Save");
    //       }
    //     })
    //     .catch((err) => {
    //       // console.log("err", err);
    //       if (err?.response?.data?.statusCode === 401) {
    //         navigate("/auth/login");
    //         localStorage.clear();
    //       }
    //       // CustomEventEmitter.emit("alert_error",err?.response?.data?.message || 'something went wrong')
    //     });
    // }
  }, [navigate, resumeId]);

  // Submit Function
  let validationSchema;
  if (activeStep === 0) {
    validationSchema = validationSchemaStep0;
  } else if (activeStep === 1) {
    validationSchema = validationSchemaStep1;
  } else if (activeStep === 2) {
    validationSchema = validationSchemaStep2;
  } else if (activeStep === 3) {
    validationSchema = validationSchemaStep3;
  } else if (activeStep === 4) {
    validationSchema = validationSchemaStep4;
  } else if (activeStep === 5 || activeStep === 6) {
    validationSchema = validationSchemaStep5;
  }
  const onSubmit = async (values, { setSubmitting }) => {
    console.log("validationSchema", validationSchema);
    try {
      setLoading(true);

      // if (activeStep === 0 && values?.file) {
      // const fileRes = await fileUpload(values.file);
      // values.personalInfo.profileImage = fileRes.profileImage;
      // values.personalInfo.profileImageS3 = fileRes.profileImageS3;
      // }
      values.personalInfo.profileImage = "";
      if (activeStep === 6) {
        values.isResumeComplete = true;
      }
      let response;
      // let canEdit = canEditResume(user?.userType);
      response = await createResume(values, resumeId);
      setLoading(false);
      if (!response) {
        return CustomEventEmitter.emit("alert_error", "Something Went Wrong");
      }
      if (activeStep !== 6) {
        console.log("kkkkk", activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        navigate(RESUME_COMPLETE_PATH);
      }
    } catch (error) {
      // console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Formik
          enableReinitialize="true"
          initialValues={data}
          onSubmit={(values, metadata) => onSubmit(values, metadata)}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {(formikProps) => (
            <>
              <Grid item xs={8} md={8} className={classes.LeftContainer}>
                <Form style={{ display: "grid" }}>
                  <StepperForm
                    formikProps={formikProps}
                    values={formikProps.values}
                    dirty={formikProps.dirty}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    loading={loading}
                    setLoading={setLoading}
                    forwardBtnName={forwardBtnName}
                  />
                </Form>
              </Grid>
              <Grid item xs={4} md={4} className={classes.rightContainer}>
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "8px solid #EBECEE",
                  }}
                >
                  <RealTimeTemplate data={data} values={formikProps.values} />
                </Box>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </Fragment>
  );
};

export default UserDashboard;

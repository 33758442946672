import * as yup from "yup";

export const validationSchemaStep0 = yup.object({
  // skillId: yup
  //   .array()
  //   .min(1, "please select atleast one skill")
  //   .required("this field is required")
  //   .nullable(),
  personalInfo: yup.object({
    fullName: yup.string().required("this field is required").nullable(),
    jobTitle: yup.string().required("this field is required").nullable(),
    aboutMe: yup.string().required("this field is required").nullable(),
  }),
});

export const validationSchemaStep1 = yup.object({
  personalInfo: yup.object({
    fullName: yup.string().required("this field is required").nullable(),
    jobTitle: yup.string().required("this field is required").nullable(),
    aboutMe: yup.string().required("this field is required").nullable(),
  }),
  educationInfo: yup.array().of(
    yup.object().shape({
      college: yup.string().required("this field is required").nullable(),
      degree: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
});

export const validationSchemaStep2 = yup.object({
  personalInfo: yup.object({
    fullName: yup.string().required("this field is required").nullable(),
    jobTitle: yup.string().required("this field is required").nullable(),
    aboutMe: yup.string().required("this field is required").nullable(),
  }),
  educationInfo: yup.array().of(
    yup.object().shape({
      college: yup.string().required("this field is required").nullable(),
      degree: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
  achievement: yup.array().of(
    yup.object().shape({
      achievementName: yup
        .string()
        .required("this field is required")
        .nullable(),
      completionYear: yup
        .string()
        .required("this field is required")
        .nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
});

export const validationSchemaStep3 = yup.object({
  personalInfo: yup.object({
    fullName: yup.string().required("this field is required").nullable(),
    jobTitle: yup.string().required("this field is required").nullable(),
    aboutMe: yup.string().required("this field is required").nullable(),
  }),
  educationInfo: yup.array().of(
    yup.object().shape({
      college: yup.string().required("this field is required").nullable(),
      degree: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
  // academicAchievement: yup.array().of(
  //   yup.object().shape({
  //     achievementName: yup
  //       .string()
  //       .required("this field is required")
  //       .nullable(),
  //     completionYear: yup
  //       .string()
  //       .required("this field is required")
  //       .nullable(),
  //     desc: yup.string().required("this field is required").nullable(),
  //   })
  // ),
  professionalHistory: yup.array().of(
    yup.object().shape({
      jobTitle: yup.string().required("this field is required").nullable(),
      employeer: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      startMonth: yup.string().required("this field is required").nullable(),
      endMonth: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
});

export const validationSchemaStep4 = yup.object({
  personalInfo: yup.object({
    fullName: yup.string().required("this field is required").nullable(),
    jobTitle: yup.string().required("this field is required").nullable(),
    aboutMe: yup.string().required("this field is required").nullable(),
  }),
  educationInfo: yup.array().of(
    yup.object().shape({
      college: yup.string().required("this field is required").nullable(),
      degree: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
  // academicAchievement: yup.array().of(
  //   yup.object().shape({
  //     achievementName: yup
  //       .string()
  //       .required("this field is required")
  //       .nullable(),
  //     completionYear: yup
  //       .string()
  //       .required("this field is required")
  //       .nullable(),
  //     desc: yup.string().required("this field is required").nullable(),
  //   })
  // ),
  professionalHistory: yup.array().of(
    yup.object().shape({
      jobTitle: yup.string().required("this field is required").nullable(),
      employeer: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      startMonth: yup.string().required("this field is required").nullable(),
      endMonth: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
  projects: yup.array().of(
    yup.object().shape({
      projectName: yup.string().required("this field is required").nullable(),
      technologyUsed: yup
        .string()
        .required("this field is required")
        .nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
});

export const validationSchemaStep5 = yup.object({
  personalInfo: yup.object({
    fullName: yup.string().required("this field is required").nullable(),
    jobTitle: yup.string().required("this field is required").nullable(),
    aboutMe: yup.string().required("this field is required").nullable(),
  }),
  educationInfo: yup.array().of(
    yup.object().shape({
      college: yup.string().required("this field is required").nullable(),
      degree: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
  // academicAchievement: yup.array().of(
  //   yup.object().shape({
  //     achievementName: yup
  //       .string()
  //       .required("this field is required")
  //       .nullable(),
  //     completionYear: yup
  //       .string()
  //       .required("this field is required")
  //       .nullable(),
  //     desc: yup.string().required("this field is required").nullable(),
  //   })
  // ),
  professionalHistory: yup.array().of(
    yup.object().shape({
      jobTitle: yup.string().required("this field is required").nullable(),
      employeer: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      startMonth: yup.string().required("this field is required").nullable(),
      endMonth: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
  professionalSkills: yup.array().of(
    yup.object().shape({
      skill: yup.string().required("this field is required").nullable(),
      level: yup.string().required("this field is required").nullable(),
    })
  ),
});

export const validationSchemaStep6 = yup.object({
  personalInfo: yup.object({
    fullName: yup.string().required("this field is required").nullable(),
    jobTitle: yup.string().required("this field is required").nullable(),
    aboutMe: yup.string().required("this field is required").nullable(),
  }),
  educationInfo: yup.array().of(
    yup.object().shape({
      college: yup.string().required("this field is required").nullable(),
      degree: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
  // academicAchievement: yup.array().of(
  //   yup.object().shape({
  //     achievementName: yup
  //       .string()
  //       .required("this field is required")
  //       .nullable(),
  //     completionYear: yup
  //       .string()
  //       .required("this field is required")
  //       .nullable(),
  //     desc: yup.string().required("this field is required").nullable(),
  //   })
  // ),
  professionalHistory: yup.array().of(
    yup.object().shape({
      jobTitle: yup.string().required("this field is required").nullable(),
      employeer: yup.string().required("this field is required").nullable(),
      startDate: yup.string().required("this field is required").nullable(),
      startMonth: yup.string().required("this field is required").nullable(),
      endMonth: yup.string().required("this field is required").nullable(),
      endDate: yup.string().required("this field is required").nullable(),
      desc: yup.string().required("this field is required").nullable(),
    })
  ),
  professionalSkills: yup.array().of(
    yup.object().shape({
      skill: yup.string().required("this field is required").nullable(),
      level: yup.string().required("this field is required").nullable(),
    })
  ),
});

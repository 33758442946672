import React, { Fragment, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import {
  ATTACH_ICON,
  EYE_ICON,
  CROSS_ICON,
  RESUME_EDIT_PATH,
  LOGIN_PATH,
  EMPLOYEES_PATH,
  BASE_URL_WITHOUT_PORT,
  PREVIEW_IN_BETWEEN,
  RESUME_LIST_PATH,
} from "../../utils/constants/constants";
import { Modal, TextField } from "@mui/material";
import IFrame from "../../components/IFrame/IFrame";
import { useNavigate } from "react-router-dom";
import { checkLoggedIn } from "../../api/Auth";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { isResumeEligible } from "../../utils/helper/checkDesignation";
import { Encyption, expiryTime } from "../../utils/helper/crypto";

const useStyles = makeStyles({
  box: {
    background: "white",
  },
  icon: {
    width: "20px",
    height: "20px",
    margin: 2,
    cursor: "pointer",
  },
  containHeaders: {
    display: "flex",
    margin: "0 0.2rem",
  },
  containHead: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem",
    borderBottom: "1px solid rgba(127, 129, 130, 0.3)",
  },
  headers: {
    marginRight: "1rem",
    fontSize: "14px",
    cursor: "pointer",
  },
  shareBtn: {
    background: "#F47954",
    color: "white",
    border: "none",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: 2,
    display: "flex",
    padding: "10px 30px",
    cursor: "pointer",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem",
    cursor: "pointer",
    textDecoration: "none",
  },
  textColor: {
    color: "#F47954",
    fontWeight: "bold",
    fontSize: "14px",
  },
  marginRight: {
    marginRight: "1rem",
  },
  mainContainer: {
    width: "100%",
  },
  leftContainer: {
    borderLeft: "1px solid rgba(127, 129, 130, 0.15)",
    padding: "0 1rem",
    margin: "0 6rem",
  },
  ideaImage: {
    width: "80px",
    height: "80px",
  },
  tipsTitle: {
    color: "#7F8182",
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "left",
  },
  ul: {
    padding: 0,
    paddingLeft: "20px",
  },
  li: {
    color: "#7F8182",
    margin: "1rem 0",
    textAlign: "left",
    maxWidth: "350px",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    p: 4,
    background: "white",
  },
  modalHeading: {
    fontWeight: "bold",
    fontSize: "16px",
    margin: 0,
  },
  containModalHead: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem",
    marginBottom: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  modalShareBtn: {
    background: "#F47954",
    border: "none",
    color: "white",
    width: "100%",
    height: "3rem",
    marginTop: "1rem",
    borderRadius: "5px",
    cursor: "pointer",
  },
  modalBody: {
    padding: "1rem",
  },
  containImage: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "1rem",
  },
});

const Dashboard = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [copied, setCopied] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [TL, setTL] = useState(false);

  const handleSharingLink = () => {
    setCopied(true);
    navigator.clipboard.writeText(
      url?.length ? url : "https://resume.resourcifi.tech"
    );
  };

  const handleOpenModal = () => {
    setOpen(true);
    setCopied(false);
  };

  useEffect(() => {
    const isLoggedIn = checkLoggedIn();
    if (isLoggedIn) {
      const user = JSON.parse(localStorage.getItem("user"));
      const ciphertext = Encyption(user.employeeId);
      setTL(user.userType === "Team Lead" ? true : false);
      const validate = isResumeEligible(user.userType);
      !validate && navigate(EMPLOYEES_PATH);
      const encodedTime = expiryTime();

      // for Production
      const previewURL = `${BASE_URL_WITHOUT_PORT}/${PREVIEW_IN_BETWEEN}/${ciphertext}`;
      
      // use for Testing
      // const previewURL = `http://resume.resourcifi.tech:4545/api/resume-detail/profile/${ciphertext}`;
      // console.log("url", previewURL);
      axios
        .get(previewURL)
        .then(() => {
          setUrl(previewURL);
          setLoading(false);
        })
        .catch((err) => {
          setUrl("");
          setLoading(false);
        });
    } else {
      navigate(LOGIN_PATH);
    }
  }, [navigate]);

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <Box className={classes.containModalHead}>
            <h4 className={classes.modalHeading}>
              {" "}
              Copy this URL and Share it.{" "}
            </h4>
            <img
              onClick={() => setOpen(false)}
              src={CROSS_ICON}
              alt=""
              className={classes.icon}
            />
          </Box>
          <Box className={classes.modalBody}>
            <TextField
              fullWidth
              disabled={true}
              placeholder={url?.length ? url : "https://resume.resourcifi.tech"}
            />
            <button
              onClick={handleSharingLink}
              className={classes.modalShareBtn}
            >
              {copied ? "Link Copied Successfully" : "Copy Sharing Link"}
            </button>
          </Box>
        </Box>
      </Modal>
      <Box className={classes.box}>
        <Box className={classes.containHead}>
          <Box className={classes.containHeaders}>
            <h5 className={classes.headers}>My Dashboard</h5>
            {TL && (
              <h5
                className={classes.headers}
                onClick={() => navigate(EMPLOYEES_PATH)}
              >
                My Team
              </h5>
            )}
          </Box>
          <Box className={classes.flex}>
            <a
              rel="noreferrer"
              href={url}
              target="_blank"
              className={classes.flex}
            >
              <img alt="" src={EYE_ICON} className={classes.icon} />
              <p style={{ margin: "0 0.5rem" }} className={classes.textColor}>
                Preview
              </p>
            </a>
            <div
              onClick={() => navigate(RESUME_EDIT_PATH)}
              className={classes.flex}
            >
              <p className={classes.textColor}>Edit Resume</p>
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <button onClick={handleOpenModal} className={classes.shareBtn}>
                <img alt="" src={ATTACH_ICON} className={classes.icon} />
                share Link
              </button>
            </div>
          </Box>
        </Box>
        {loading ? (
          <Loader />
        ) : (
          <Box className={classes.mainContainer}>
            <Box
              style={{
                width: "97%",
                height: "100%",
                border: "8px solid #EBECEE",
                margin: "0 1.5%",
              }}
            >
              <IFrame resumeLink={url} />
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default Dashboard;

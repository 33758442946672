import axios from "axios";
import { BASE_URL } from "../utils/constants/constants";

// export const fileUpload = async (file) => {
//   let response = {};
//   let formData = new FormData();
//   formData.append("file", file);
//   const user = JSON.parse(localStorage.getItem("user"));

//   const config = {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       Authorization: `Bearer ${user?.token}`,
//     },
//   };
//   try {
//     const url = `${BASE_URL}/api/resume-detail/image/upload`;
//     response = await axios.post(url, formData, config);
//     response = response.data;
//   } catch (err) {
//     // console.log("Error in upload method of HTTP" + err);
//     throw err;
//   }
//   return response?.data;
// };

export const createResume = async (data, resumeId) => {
  const user1 = JSON.parse(localStorage.getItem("user"));
  const config1 = {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${user1?.token}`,
    },
  };
  let response = null;
  try {
    const url = `${BASE_URL}/api/resume-detail/add/${resumeId}`;
    response = await axios.post(url, data, config1);
    response = response.data;
  } catch (error) {
    console.error("error while adding resume details", error);
  }
  return response;
};

// export const removeProfileImage = async (userId) => {
//   const user1 = JSON.parse(localStorage.getItem("user"));
//   // debugger;
//   // debugger;
//   const config1 = {
//     headers: {
//       "Content-Type": "Application/json",
//       Authorization: `Bearer ${user1?.token}`,
//     },
//   };
//   let response = null;
//   try {
//     const url = `${BASE_URL}/api/resume-detail/remove/resume/image/${userId}`;
//     response = await axios.delete(url, config1);
//     response = response.data;
//   } catch (error) {
//     response = error.response.data;
//     console.error("error while deleting profile image", error);
//   }
//   return response;
// };

export const createResumeTeamLead = async (data, id) => {
  const user1 = JSON.parse(localStorage.getItem("user"));
  const config1 = {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${user1?.token}`,
    },
  };
  let response = null;
  try {
    const url = `${BASE_URL}/api/resume-detail/add/TL-PC/${id}`;
    response = await axios.post(url, data, config1);
    response = response.data;
  } catch (error) {
    console.error("error while adding resume details", error);
  }
  return response;
};

export const getSkillList = async () => {
  const user1 = JSON.parse(localStorage.getItem("user"));
  const config1 = {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${user1?.token}`,
    },
  };
  let response = null;
  try {
    const url = `${BASE_URL}/api/skill/dropdown/list`;
    response = await axios.get(url, config1);
  } catch (error) {
    // console.log("Failed to fetch users due to", error);
  }
  return response?.data?.data || [];
};

export const getResumeList = async () => {
  const user1 = JSON.parse(localStorage.getItem("user"));
  // debugger;
  const config1 = {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${user1?.token}`,
    },
  };
  let response = null;
  try {
    const url = `${BASE_URL}/api/resume-detail/user/resume/list`;
    response = await axios.get(url, config1);
    // console.log("rrrrrrr", response);
  } catch (error) {
    // console.log("Failed to fetch users due to", error);
  }
  return response?.data || [];
};

export const getResumeDetailsForEdit = async (resumeId) => {
  const user1 = JSON.parse(localStorage.getItem("user"));
  const config1 = {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${user1?.token}`,
    },
  };
  let response = null;
  try {
    const url = `${BASE_URL}/api/resume-detail/user/${resumeId}`;
    response = await axios.get(url, config1);
  } catch (error) {
    // console.log("Failed to fetch users due to", error);
  }
  return response?.data?.data || [];
};

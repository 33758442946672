import { FORGOT_PASSWORD, LOGIN_PATH } from "../utils/constants/constants";
import Login from "../screens/Login/Login";
import ForgotPassword from "../screens/ForgotPassword/ForgotPassword";

export const authRoutes = [
    {
        path: LOGIN_PATH,
        component: Login
      },
      {
        path: FORGOT_PASSWORD,
        component: ForgotPassword
      },

]